import React, { useEffect, useState } from 'react';
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import { useAccount } from 'context/AccountProvider';
import { getUserAccessProfileWise } from 'services/UserAccessMasterService';
import { Card, Table, TableRow, TableBody, TableCell, TableContainer, TablePagination, CardContent } from '@mui/material';
import { UserListHead, UserListToolbar } from 'sections/@dashboard/user';

const TABLE_HEAD = [
    { id: 'role', label: 'Role', alignRight: false },
    { id: 'screen', label: 'Screen', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
    { id: 'module', label: 'Module', alignRight: false },
    { id: 'department', label: 'Department', alignRight: false },
    { id: 'designation', label: 'Designation', alignRight: false },
    { id: 'practice_area', label: 'Practice Area', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.screen?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


function MyAccess() {
    const { userDetails } = useAccount()
    const [myAccessList, setMyAccessList] = useState([])
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    useEffect(() => {
        if (userDetails?.id) {
            fetchMyAccess()
        }
    }, [userDetails?.id])

    const fetchMyAccess = async () => {

        const res = await getUserAccessProfileWise(userDetails?.id);
        debugger
        if (res) {
            if (res?.response?.status === 500) {
                setMyAccessList([])
            }
            else {
                setMyAccessList(res)
            }
        }
        else {
            setMyAccessList([])
        }
        console.log(res, 'setMyAccessList');
    }
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - myAccessList?.length) : 0;

    const filterCoupon = applySortFilter(myAccessList, getComparator(order, orderBy), filterName);

    const isDataNotFound = filterCoupon?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




    return (
        <>
            <Card padding={5} >
                <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                <CardContent>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 400 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={myAccessList?.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {filterCoupon?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                        const { id, role, screen, action, designation, department, module, practice_area, status } = row;
                                        return (
                                            <TableRow
                                                hover
                                                // key={id}
                                                tabIndex={-1}
                                            >
                                                <TableCell align="left"> {role ? role : '-'} </TableCell>
                                                <TableCell align="left"> {screen ? screen : '-'} </TableCell>
                                                <TableCell align="left"> {action ? action : '-'} </TableCell>
                                                <TableCell align="left"> {module ? module : '-'} </TableCell>
                                                <TableCell align="left"> {department ? department : '-'} </TableCell>
                                                <TableCell align="left"> {designation ? designation : '-'} </TableCell>
                                                <TableCell align="left"> {practice_area ? practice_area : '-'} </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={13} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isDataNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </CardContent>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={myAccessList?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card >
        </>
    )
}

export default MyAccess