import { Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField, TextareaAutosize } from '@mui/material';
import { Stack, styled } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { convertToBase64 } from 'components/CommonFunction';
import ModalComponent from 'components/Modal';
import Toast from 'components/Toast';
import { useAccount } from 'context/AccountProvider';
import moment from 'moment';
import React, { useEffect } from 'react';
import { getExpenseCategoryMaster, postExpanseCreate, postExpanseUpdate } from 'services/expanseService';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '10px 0',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%'
}));

const AddExpanseModal = ({ handleClose, open, getExpanseData, editData, invoiceData }) => {
    const { userDetails } = useAccount();
    // userDetails?.organisation?.id
    const [formData, setFormData] = React.useState({
        expense_date: moment(new Date()).format('YYYY-MM-DD'),
        expense_category: '',
        description: '',
        amount: '',
        expense_documents: ''
    })

    const [toastData, setToastData] = React.useState({
        open: false,
        message: '',
        status: ''
    });
    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    useEffect(() => {
        if (editData?.expanseDataValue) {
            const { expanseDataValue } = editData;
            setFormData({
                expense_date: expanseDataValue?.expense_date,
                expense_category: expanseDataValue?.expense_category,
                description: expanseDataValue?.description,
                amount: expanseDataValue?.amount,
                expense_documents: expanseDataValue?.expense_documents
            });
        }
    }, [editData?.expanseDataValue]);

    const [categoryOptions, setCategoryOptions] = React.useState([]);

    const fetchOrgPracticeAreaData = async () => {
        await getExpenseCategoryMaster()
            .then((res) => {
                console.log(res, 'res');
                // setIsLoading(false);
                // category_name, sali_code
                setCategoryOptions(
                    res?.data.map((item) => ({ label: `${item?.sali_code} ${item.category_name}`, value: item.category_name }))
                );
                // SetExpanseListData(res?.data);
            })
            .catch((err) => {
                console.log(err, 'err');
            });

    };

    React.useEffect(() => {
        fetchOrgPracticeAreaData();
    }, []);

    const handleDateChange = (e, name) => {
        setFormData({ ...formData, [name]: moment(new Date(e)).format('YYYY-MM-DD') });
    };

    const handleChange = (value, name) => {
        if (name === 'expense_documents') {
            convertToBase64(value, (base64String) => {
                setFormData({ ...formData, [name]: base64String });
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async () => {
        if (!formData?.expense_category || !formData?.amount) {
            setToastData({
                open: true,
                status: 'error',
                message: 'Please fill all the required fields'
            });
            return;
        }
        const data = {
            ...formData,
            client_id: invoiceData?.client_id,
            matter_id: invoiceData?.matter_id,
            expense_date: moment(new Date(formData.expense_date)).format('YYYY-MM-DD'),
            expense_documents: formData.expense_documents
                ? Array.isArray(formData.expense_documents)
                    ? formData.expense_documents
                    : [formData.expense_documents]
                : []
        };
        if (editData?.isEdit && editData?.editId) {
            await postExpanseUpdate(userDetails?.organisation?.id, editData?.editId, data)
                .then((res) => {
                    getExpanseData();
                    setToastData({
                        open: true,
                        status: 'success',
                        message: 'Expense updated successfully'
                    });
                    handleClose();
                    setFormData({
                        expense_date: moment(new Date()).format('YYYY-MM-DD'),
                        expense_category: '',
                        description: '',
                        amount: '',
                        expense_documents: ''
                    });
                })
                .catch((err) => {
                    setToastData({
                        open: true,
                        status: 'error',
                        message: err?.response?.data?.message || 'Something went wrong'
                    });
                });
        } else {
            await postExpanseCreate(userDetails?.organisation?.id, data)
                .then((res) => {
                    getExpanseData();
                    setToastData({
                        open: true,
                        status: 'success',
                        message: 'Expense added successfully'
                    });
                    handleClose();
                    setFormData({
                        expense_date: moment(new Date()).format('YYYY-MM-DD'),
                        expense_category: '',
                        description: '',
                        amount: '',
                        expense_documents: ''
                    });
                })
                .catch((err) => {
                    setToastData({
                        open: true,
                        status: 'error',
                        message: err?.response?.data?.message || 'Something went wrong'
                    });
                });
        }
    };
    return (
        <>
            {/* // <div> */}
            <ModalComponent width="500" open={open} handleClose={() => handleClose()}>
                <Stack mb={2}>
                    <h4>New Expense</h4>
                </Stack>

                <Stack
                    display="grid"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                        height: '100%',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: 10,
                        maxHeight: 'calc(100vh - 250px)',
                        overflowX: 'hidden'
                    }}
                >
                    <Item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                size="small"
                                fullWidth
                                label="Date"
                                className="date-picker-add-expense"
                                inputFormat="YYYY-MM-DD"
                                value={formData?.expense_date}
                                onChange={(e) => handleDateChange(e, 'expense_date')}
                                required
                                renderInput={(params) => <TextField style={{ width: '100%' }} {...params} required />}
                            />
                        </LocalizationProvider>
                    </Item>
                    <Item>
                        <FormControl fullWidth size="small">
                            <InputLabel id="contact_person">Category</InputLabel>
                            <Select
                                size="small"
                                sx={{ width: '100%', textAlign: 'left' }}
                                // type="text"
                                label="Category"
                                variant="outlined"
                                displayEmpty
                                placeholder="Select Category"
                                defaultValue={-1}
                                name="expense_category"
                                onChange={(e) => handleChange(e.target.value, 'expense_category')}
                                value={formData?.expense_category}
                                fullWidth
                                inputProps={{ 'aria-label': 'Without label' }}
                                required
                            >
                                <MenuItem value={-1}>None</MenuItem>
                                {categoryOptions?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                </Stack>
                <Item sx={{ paddingBlock: '0px' }}>
                    <TextareaAutosize
                        aria-label="Description"
                        minRows={4}
                        placeholder="Description"
                        style={{ width: '100%', padding: '10px' }}
                        value={formData?.description}
                        name="description"
                        onChange={(e) => handleChange(e.target.value, 'description')}
                    />
                </Item>

                <Stack
                    display="grid"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                        height: '100%',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: 10,
                        maxHeight: 'calc(100vh - 250px)',
                        overflowX: 'hidden'
                    }}
                >
                    {/* amount, file upload */}
                    <Item>
                        <TextField
                            size="small"
                            sx={{ width: '100%' }}
                            type="text"
                            label="Amount"
                            variant="outlined"
                            placeholder="Amount"
                            name="amount"
                            value={formData?.amount}
                            onChange={(e) => handleChange(e.target.value, 'amount')}
                        />
                    </Item>
                    <Item>
                        <input
                            type="file"
                            style={{ width: '100%', padding: '10px' }}
                            name="expense_documents"
                            onChange={(e) => handleChange(e.target.files[0], 'expense_documents')}
                        />
                    </Item>
                </Stack>

                <Stack direction={'row'} alignItems="center" gap={2} mt={2} style={{ maxWidth: '267px' }}>
                    <Button variant="contained" fullWidth color="primary" type="submit" onClick={() => handleSubmit()}>
                        Save
                    </Button>
                    <Button variant="outlined" onClick={() => handleClose()} color="primary" fullWidth>
                        Cancel
                    </Button>
                </Stack>
            </ModalComponent>
            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
        // </div>
    );
};

export default AddExpanseModal;
