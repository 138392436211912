import React, { useEffect, useState } from 'react'
import { Button, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material'
import { Stack, styled, width } from '@mui/system'
import Iconify from 'components/Iconify'
import ModalComponent from 'components/Modal'
import Toast from 'components/Toast'
import { useAccount } from 'context/AccountProvider'
import { getMatterByClient } from 'services/ClientService'
import { DeleteAssignDataRoleMapping, assignValueToData, getDataRoleValue, updateValueToData } from 'services/DataRoleService'
import Loader from 'ui-component/Loader'
import NewModalComponent from 'components/NewModalComponent'
import StyledTableListHead from 'views/StyledTable/StyledTableListHead'






const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td ': {
        borderRight: "1px solid #D1D9E0 !important"
    },
    'th ': {
        borderBottom: "1px solid #D1D9E0 !important"
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    '&:last-child': {
        borderRight: '0 !important',
    },
    fontSize: '14px',
    fontWeight: 500,
    color: '#404040',
    padding: '10px !important',

}));



const TABLE_HEAD = [
    { id: 'Client', label: 'Client', alignRight: false },
    { id: 'Matter', label: 'Matter', alignRight: false },
    { id: 'actions', label: 'action', alignRight: false },
];




function AssignClienMatterPopup({ open, handleClose, activeDataRoleId, allClientList, allMatterList }) {
    const { userDetails } = useAccount();
    const [IsLoading, setIsLoading] = useState(false)
    const [client_matter, setClient_matter] = useState([
        { "client_id": '', "matter_id": '' }
    ])

    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    const [matterList, setMatterList] = useState([])
    const [assignedData, setAssignedData] = useState([])

    useEffect(() => { fetchAssignValueOfDataRole() }, [])

    const fetchAssignValueOfDataRole = async () => {
        const res = await getDataRoleValue(userDetails.organisation.id, activeDataRoleId);
        if (res.data) {
            debugger
            setAssignedData(res?.data[0])
        }
        else { setAssignedData([]) }
    }

    const handleClientMatter = async (e) => {
        const { name, value } = e.target;
        setClient_matter(prevState => [{
            ...prevState[0],  // Preserve the existing matter_id field
            [name]: value  // Update the client_id field with the new value
        }]);

        if (name === 'client_id') {
            setMatterList([]);
            const res = await getMatterByClient(value).then((response) => {
                if (response) {
                    setMatterList(response);
                } else {
                    setToastData({
                        open: true,
                        status: 'error',
                        message: response?.response?.data?.message || 'Something went wrong'
                    });
                }
            })
        }
    };


    //assign value to data role 
    const assignDataValue = async () => {
        setIsLoading(true)
        let payload = {
            "role": activeDataRoleId,
            "data_role": { client_matter: client_matter }
        }

        const res = await assignValueToData(userDetails.organisation.id, payload);

        if (res?.message === "Data created successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchAssignValueOfDataRole()
            setIsLoading(false)
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || 'Something went wrong'
            });
            setIsLoading(false)
        }
    }


    const unAssingnDataValue = async (item) => {
        setIsLoading(true)
        const filteredData = assignedData?.data_role?.client_matter?.filter(_ => !(_.matter_id === item.client_id || _.matter_id === item.matter_id));
        let payload = {
            "role": activeDataRoleId,
            "data_role": { client_matter: filteredData }
        }

        const res = await updateValueToData(userDetails.organisation.id, assignedData?.id, payload);

        if (res?.message === "Data Update successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchAssignValueOfDataRole()
            setIsLoading(false)
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || 'Something went wrong'
            });
            setIsLoading(false)
        }
    }

    return (
        <>
            {IsLoading && <Loader />}
            <NewModalComponent
                width="calc(100% - 40px)"
                maxWidth="800px"
                open={open}
                handleClose={handleClose}
            >

                <Stack p={3} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Stack mt={1} sx={{ width: '100%' }} alignContent={'flex-start'}>
                        <h4> Assign Client & Matter </h4>
                    </Stack>

                    <TableContainer sx={{ height: "400px" }}>
                        <Table
                            sx={{
                                background: 'white',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                border: '1px solid #D1D9E0',
                                borderCollapse: 'unset',
                                overflow: 'hidden'
                            }}
                        >

                            <StyledTableListHead
                                headLabel={TABLE_HEAD}
                                rowCount={assignedData?.data_role?.client_matter?.length}
                            />

                            {console.log("assignedData", assignedData)}
                            <TableBody>
                                {assignedData?.data_role?.client_matter?.map((item, i) => {
                                    return (
                                        <StyledTableRow key={i}>
                                            {console.log("mk", allClientList?.find((_) => _.id === item?.client_id))}
                                            <StyledTableCell width={'50%'}> {allClientList?.find((_) => _.id === item?.client_id)?.name} </StyledTableCell>
                                            <StyledTableCell width={'50%'}> {allMatterList?.find((_) => _.id === item?.matter_id)?.name} </StyledTableCell>
                                            <StyledTableCell width={'100px'} >
                                                <Iconify style={{ cursor: "pointer" }} icon="eva:trash-2-outline" width={24} height={24} onClick={() => unAssingnDataValue(item)} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}


                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Stack flexDirection={'row'} style={{ width: '100%' }} gap={2}>

                        <FormControl fullWidth>
                            <InputLabel size='small' id="demo-simple-select-label">Client</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Client"
                                name="client_id"
                                onChange={handleClientMatter}
                                size='small'
                            >
                                <MenuItem value={-1}>None</MenuItem>
                                {allClientList?.map((item) => (
                                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel size='small' id="demo-simple-select-label">Matter</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Matter"
                                name="matter_id"
                                size='small'
                                onChange={handleClientMatter}
                            >
                                <MenuItem value={-1} disabled>None</MenuItem>
                                {matterList?.map((item) => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {console.log("client_matter11", client_matter[0].client_id.length > 0)}


                        <button
                            disabled={client_matter.every(obj => obj.client_id.length < 1 || obj.matter_id.length < 1)}
                            className='blue_btn'
                            style={{ width: "100%", minWidth: '180px', height: '40.13px' }}
                            type='submit'
                            onClick={assignDataValue}
                        >
                            Assign
                        </button>

                    </Stack>

                </Stack>
            </NewModalComponent >

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignClienMatterPopup