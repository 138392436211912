import React, { useState } from 'react'
import noplan from 'assets/images/noplan.svg';
import { Button, Card, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAccount } from 'context/AccountProvider';
import ModalComponent from 'components/Modal';
import WarningIcon from '@mui/icons-material/Warning';
function NoSubscriptions() {
    const { userDetails } = useAccount();
    const [orgCheck, setOrgCheck] = useState(false)
    const navigate = useNavigate()

    const hanldeRedirection = () => {
        if (userDetails?.roles?.includes("org_admin", "org_user") && userDetails?.organisation === null) {
            setOrgCheck(true)
        }
        else {
            navigate('/dashboard/purchase-plans')
        }
    }

    const handleClosePurchaseModal = () => {
        setOrgCheck(false);
    }
    return (
        <>
            <div className='noplan'>
                <div>
                    <img src={noplan} alt='no plan' />
                </div>
                <div>
                    <h4> You have currently  no plans </h4>
                    <p>
                        Get started with your Subscriptions by purchasing plans and assigning them to your organization. Click 'Purchase Plans' or ‘Contact Us’ below to begin.
                    </p>
                </div>
                <div className="btn_wrp">
                    <Stack direction={"row"} gap={2}>
                        <button onClick={() => hanldeRedirection()}>
                            Purchase Plans
                        </button>
                        <button >
                            Contact Us
                        </button>
                    </Stack>
                </div>

            </div>

            <ModalComponent width="calc(100% - 30px)" maxWidth="500px" open={orgCheck} handleClose={handleClosePurchaseModal}>
                <Card>
                    <Stack padding={5} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                        <h4 style={{ marginBottom: "15px" }}> <WarningIcon color="info" /> Alert </h4>
                        <p style={{ marginBottom: "25px !important", textAlign: "center" }}>
                            You Dont Have Any Organization Please Create Your Organization to purchase plan.
                        </p>
                        <Stack direction={"row"} gap={2}>

                            <Button
                                variant="contained"
                                onClick={() => { navigate('/dashboard/create-organization') }}
                                color="primary"
                                style={{ marginTop: "20px" }}
                            >
                                Create Organization
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleClosePurchaseModal();
                                }}
                                color="primary"
                                style={{ marginTop: "20px" }}
                            >
                               Close
                            </Button>
                        </Stack>

                    </Stack>
                </Card>
            </ModalComponent>

        </>
    )
}

export default NoSubscriptions