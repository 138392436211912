/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';
// material
import { Box, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { styled } from '@mui/system';

// ----------------------------------------------------------------------

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)'
};


StyledTableListHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    // rowCount: PropTypes.number,
    headLabel: PropTypes.array,
    // numSelected: PropTypes.number,
    onRequestSort: PropTypes.func
    // onSelectAllClick: PropTypes.func,
};



export default function StyledTableListHead({
    order,
    orderBy,
    // rowCount,
    headLabel,
    // numSelected,
    onRequestSort
    // onSelectAllClick,
}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={{ position: "sticky", top: "-1px", zIndex: 1, backgroundColor: "#fff" }}>
            <TableRow >

                {headLabel.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            // minWidth: '100px',
                            border: 0,
                            borderRight: "1px solid #D1D9E0 !important",
                            borderBottom: "1px solid #D1D9E0 !important",
                            fontSize: '14px',
                            fontWeight: 700,
                            color: '#303030',
                            bgcolor: '#F8F9FA',
                            padding: '10px !important',
                            '&:last-child': {
                                // display: 'none',
                                width: 'auto',
                                border: 0,
                                borderLeft: '0 !important',
                                borderRight: '0 !important',
                            },
                        }}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
