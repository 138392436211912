import {
    Button,
    Card,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { styled } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatNumberIndian, numberToword, secondsToHms } from 'components/CommonFunction';
import Iconify from 'components/Iconify';
import ModalComponent from 'components/Modal';
import Toast from 'components/Toast';
import { useAccount } from 'context/AccountProvider';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getAllClient, getMatterByClient } from 'services/ClientService';
import { createInvoice, genratePdf, getDefuleInvoice, getInvoiceById, getTimeSheetData, updateInvoice } from 'services/InvoiceService';
import { getAllOrganizationUsers } from 'services/OrganizationService';
import Loader from 'ui-component/Loader';
import AddExpanseModal from './AddExpanseModal';
import { IoMdClose } from 'react-icons/io';
import { deleteExpanse, getExpanseList, getExpanseListClientMatter } from 'services/expanseService';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary
}));

const feeType = [
    { name: 'Prof Fee', value: 'prof_fee' },
    { name: 'Expenses', value: 'expenses' }
];

let labelValueObject = [];

const CreateInvoice = () => {
    const { userDetails } = useAccount();
    const { id } = useParams();
    const navigate = useNavigate();
    const [IsLoading, setIsLoading] = useState(false);
    const [allClient, setAllClient] = useState([]);
    const [allMatterList, setMatterList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedMatter, setSelectedMatter] = useState();
    const [pdfData, setPDFData] = useState({});
    const [previewInvoice, setPreviewInvoice] = useState(false);
    const [showNewExpanseModal, setShowNewExpanseModal] = useState(false);
    const [defultInvoiceData, setDefultInvoiceData] = useState({});
    const [addedExpanseData, setAddedExpanseData] = useState({
        data: []
    });

    const [invoiceeName, setInvoiceeName] = useState([]);
    const [invoiceeIs, setInvoiceeIs] = useState('');

    const [timeSheetFilterDate, setTimeSheetFilterDate] = useState({
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    });
    const [timeSheetData, setTimeSheetData] = useState({
        data: {},
        isLoading: false,
        total: 0
    });
    const [invoiceNumbertype, setInvoiceNumbertype] = useState({
        invoiceNumber: 'auto',
        prefix: '',
        next_number: ''
    });
    const [popupData, setPopupData] = useState({
        title: '',
        show: false,
        text: ''
    });
    const [invoiceData, setinvoiceData] = useState({
        client_id: '',
        matter_id: '',
        po_number: '',
        invoice_id: '',
        billToName: '',
        invoice_date: moment().format('YYYY-MM-DD'),
        due_date: moment().format('YYYY-MM-DD'),
        subject: '',
        contact_person: '',
        business_unit: userDetails?.organisation?.address,
        billing_address: '',
        currency: 'INR',
        initiated_by: '',
        status: 'Draft',
        place_of_supply: '',
        invoicee_Id: ''
    });

    const [billItems, setBillItems] = useState([
        {
            sno: 1,
            fee_type: 'prof_fee',
            itemName: '',
            amount: ''
        },
        {
            sno: 2,
            fee_type: 'expenses',
            itemName: '',
            amount: ''
        }
    ]);
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });
    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    const getExpanseData = async (client, matter, startDate, endDate) => {
        // await getExpanseList(userDetails?.organisation?.id)

        const timezoneOffsetInMinutes = 5 * 60 + 30; // +05:30 in minutes
        let start = startDate ? new Date(startDate) : new Date(timeSheetFilterDate?.startDate);
        let end = endDate ? new Date(endDate) : new Date(timeSheetFilterDate?.endDate);

        const adjustedStart = new Date(start.getTime() - timezoneOffsetInMinutes * 60000);
        const adjustedEnd = new Date(end.getTime() - timezoneOffsetInMinutes * 60000);

        let payload = {
            client_id: client || invoiceData.client_id,
            matter_id: matter || invoiceData.matter_id,
            // start_date: new Date(startDate).toISOString() || new Date(timeSheetFilterDate?.startDate).toISOString(),
            // end_date:  new Date(endDate).toISOString() || new Date(timeSheetFilterDate?.endDate).toISOString()
            start_date: moment(adjustedStart).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + '.002Z',
            end_date: moment(adjustedEnd).endOf('day').format('YYYY-MM-DDTHH:mm:ss') + '.002Z'
        };


        await getExpanseListClientMatter(userDetails?.organisation?.id, payload.client_id, payload.matter_id, payload.start_date, payload.end_date)
            .then((res) => {
                if (res?.data) {
                    setAddedExpanseData({ ...res });
                } else {
                    setAddedExpanseData({
                        data: []
                    });
                }
            })
            .catch((err) => {
                console.log(err, 'err');
            });

    };

    const invoiceBaseOnId = async (ID, orgId) => {
        setIsLoading(true);

        await getInvoiceById(orgId, ID)
            .then((response) => {
                if (response?.data) {
                    const data = {
                        client_id: response?.data?.client_id,
                        matter_id: response?.data?.matter_id,
                        po_number: response?.data?.po_number,
                        invoice_id: response?.data?.invoice_number,
                        billToName: response?.data?.invoicee_name,
                        invoice_date: response?.data?.invoice_date,
                        due_date: response?.data?.due_date,
                        subject: response?.data?.subject,
                        contact_person: response?.data?.contact_person,
                        business_unit: response?.data?.business_unit,
                        billing_address: response?.data?.billing_address,
                        currency: response?.data?.currency,
                        initiated_by: response?.data?.initiated_by,
                        status: response?.data?.status,
                        place_of_supply: response?.data?.place_of_supply
                    };
                    setinvoiceData(data);
                    const BILL_ITEMS = response?.data?.line_items.map((item, i) => {
                        return {
                            sno: item.serial_num,
                            fee_type: item.fee_type === 'Service Fee' ? 'prof_fee' : 'expenses',
                            itemName: item.description,
                            amount: item.amount
                        };
                    });
                    getInvoiceeName(response?.data?.matter_id);
                    setBillItems(BILL_ITEMS);
                    setTimeSheetFilterDate({
                        startDate: response?.data?.timesheet_start_date || timeSheetFilterDate.startDate,
                        endDate: response?.data?.timesheet_end_date || timeSheetFilterDate.endDate
                    });
                    getInvoiceTimeSheet(
                        response?.data?.client_id,
                        response?.data?.matter_id,
                        response?.data?.timesheet_start_date || timeSheetFilterDate.startDate,
                        response?.data?.timesheet_end_date || timeSheetFilterDate.endDate
                    );
                    getExpanseData(
                        response?.data?.client_id,
                        response?.data?.matter_id,
                        response?.data?.timesheet_start_date || timeSheetFilterDate.startDate,
                        response?.data?.timesheet_end_date || timeSheetFilterDate.endDate
                    )
                    setIsLoading(false);

                    // setinvoiceData(response?.data);
                    // setBillItems(response?.data?.line_items);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err, 'err');
            });
    };

    useEffect(() => {
        if (id && userDetails?.organisation?.id) {
            invoiceBaseOnId(id, userDetails?.organisation?.id);
        }
    }, [id, userDetails?.organisation?.id]);

    const getInvoiceTimeSheet = async (client, matter, startDate, endDate) => {
        const timezoneOffsetInMinutes = 5 * 60 + 30; // +05:30 in minutes
        let start = startDate ? new Date(startDate) : new Date(timeSheetFilterDate?.startDate);
        let end = endDate ? new Date(endDate) : new Date(timeSheetFilterDate?.endDate);

        const adjustedStart = new Date(start.getTime() - timezoneOffsetInMinutes * 60000);
        const adjustedEnd = new Date(end.getTime() - timezoneOffsetInMinutes * 60000);

        const payload = {
            client_id: client || invoiceData.client_id,
            matter_id: matter || invoiceData.matter_id,
            // start_date: new Date(startDate).toISOString() || new Date(timeSheetFilterDate?.startDate).toISOString(),
            // end_date:  new Date(endDate).toISOString() || new Date(timeSheetFilterDate?.endDate).toISOString()
            start_date: moment(adjustedStart).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + '.002Z',
            end_date: moment(adjustedEnd).endOf('day').format('YYYY-MM-DDTHH:mm:ss') + '.002Z'
        };

        // const payload = {
        //     client_id: client || invoiceData.client_id,
        //     matter_id: matter || invoiceData.matter_id,
        //     start_date: startDate || timeSheetFilterDate.startDate,
        //     end_date: endDate || timeSheetFilterDate.endDate
        // };

        if (payload.client_id && payload.matter_id) {
            setTimeSheetData({ ...timeSheetData, isLoading: true });
            await getTimeSheetData(payload).then((response) => {
                if (response?.data) {
                    console.log(response, 'response');
                    setTimeSheetData({ data: response?.data, isLoading: false });
                } else {
                    setTimeSheetData({ ...timeSheetData, data: {}, isLoading: false });
                    // setToastData({
                    //     open: true,
                    //     status: 'error',
                    //     message: response?.response?.data?.message || 'Something went wrong'
                    // });
                }
            });
        }
    };

    useEffect(() => {
        getDefuleInvoiceHandler();
        getAllClientHandler();
        getAllUser();
        // getInvoiceTimeSheet();
    }, []);

    useEffect(() => {
        if (userDetails?.organisation?.id) {
            setinvoiceData({ ...invoiceData, business_unit: userDetails?.organisation?.address });
            // getExpanseData();
        }
    }, [userDetails?.organisation?.id]);

    const handleClosePopup = () => {
        setPopupData({
            title: '',
            show: !popupData.show,
            text: ''
        });
    };

    //get defult invoice data
    const getDefuleInvoiceHandler = async () => {
        const res = await getDefuleInvoice();
        if (res.data) {
            setDefultInvoiceData(res.data);
            labelValueObject = [{ label: res.data.templateName, value: res.data.templateName }];
        } else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || 'Something went wrong'
            });
        }
    };

    //get all client
    const getAllClientHandler = async () => {
        const res = await getAllClient();
        if (res?.length) {
            setAllClient(res);
        } else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || 'Something went wrong'
            });
        }
    };

    //get all Matter
    const getMatterByClientHandler = useCallback(async (id) => {
        setMatterList([]);
        // setinvoiceData({ ...invoiceData, matter_id: '' });
        const res = await getMatterByClient(id).then((response) => {
            if (response) {
                setMatterList(response);
            } else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: response?.response?.data?.message || 'Something went wrong'
                });
            }
        });

        // console.log(res, 'resMatter');
        // if (res && res.length > 0) {
        //     setMatterList(res);
        // } else {
        //     setToastData({
        //         open: true,
        //         status: 'error',
        //         message: res?.response?.data?.message || 'Something went wrong'
        //     });
        // }
    }, []);

    const getAllUser = async (second) => {
        let res = await getAllOrganizationUsers();
        if (res?.data?.length > 0) {
            setUserList(res?.data);
        } else {
            setUserList([]);
        }
    };

    useEffect(() => {
        if (invoiceData.client_id) {
            getMatterByClientHandler(invoiceData.client_id);
        }
    }, [invoiceData.client_id, getMatterByClientHandler]);

    const handleChange = (value, name) => {
        setinvoiceData({ ...invoiceData, [name]: value });
        if (name === 'billToName') {
            if (value === selectedMatter?.billing_contact_name) {
                setinvoiceData({
                    ...invoiceData,
                    [name]: value,
                    place_of_supply: selectedMatter?.billing_state
                });
                setInvoiceeIs('matter');
            } else {
                setinvoiceData({
                    ...invoiceData,
                    [name]: value,
                    place_of_supply: selectedClient?.billing_state
                });
                setInvoiceeIs('client');
            }
        }
        if (name === 'client_id') {
            let filterClient = allClient.filter((_) => _.id === value);
            setSelectedClient(filterClient[0]);
            setContactData(filterClient[0]?.contact_person);
            setinvoiceData({ ...invoiceData, [name]: value, ['billToName']: null, ['matter_id']: null, ['contact_person']: null });
        }

        if (name === 'matter_id') {
            setinvoiceData({ ...invoiceData, [name]: value });
            getInvoiceTimeSheet(invoiceData.client_id, value)
            getExpanseData(invoiceData.client_id, value)
        }
    };

    useEffect(() => {
        if (invoiceData?.matter_id) {
            getInvoiceeName(invoiceData?.matter_id);
        }
    }, [invoiceData?.matter_id, invoiceData?.client_id, JSON?.stringify(allClient), JSON?.stringify(allMatterList)]);

    const handleDateChange = (date, name) => {
        setinvoiceData({ ...invoiceData, [name]: moment(new Date(date)).format('YYYY-MM-DD') });
    };

    const handleTimeSheetDateChange = (date, name) => {
        setTimeSheetFilterDate({ ...timeSheetFilterDate, [name]: moment(new Date(date)).format('YYYY-MM-DD') });
        if (name === 'startDate') {
            setTimeSheetFilterDate({ ...timeSheetFilterDate, [name]: moment(new Date(date)).format('YYYY-MM-DD') });
            getInvoiceTimeSheet(
                invoiceData.client_id,
                invoiceData.matter_id,
                moment(new Date(date)).format('YYYY-MM-DD'),
                timeSheetFilterDate?.endDate
            );
            getExpanseData(invoiceData.client_id,
                invoiceData.matter_id,
                moment(new Date(date)).format('YYYY-MM-DD'),
                timeSheetFilterDate?.endDate)
        } else {
            setTimeSheetFilterDate({ ...timeSheetFilterDate, [name]: moment(new Date(date)).format('YYYY-MM-DD') });
            getInvoiceTimeSheet(
                invoiceData.client_id,
                invoiceData.matter_id,
                timeSheetFilterDate?.startDate,
                moment(new Date(date)).format('YYYY-MM-DD')
            );
            getExpanseData(
                invoiceData.client_id,
                invoiceData.matter_id,
                timeSheetFilterDate?.startDate,
                moment(new Date(date)).format('YYYY-MM-DD')
            )
        }
    };

    //handle add billitem
    const addNewItem = () => {
        const newsno = billItems.length + 1;
        const newItem = {
            sno: newsno,
            fee_type: 'prof_fee',
            itemName: '',
            amount: ''
        };
        setBillItems([...billItems, newItem]);
    };

    //handle  billitem change
    const handleInputChange = (sno, value, field) => {
        const updatedbillItems = billItems.map((item) => {
            if (item.sno === sno) {
                return {
                    ...item,
                    [field]: value
                };
            }
            return item;
        });
        setBillItems(updatedbillItems);
    };

    //handle remove billitem
    const handleDeleteItem = (sno) => {
        // Prevent deletion of the first two items
        if (sno > 1) {
            const updatedbillItems = billItems.filter((item) => item.sno !== sno);
            setBillItems(updatedbillItems);
        }
    };

    //handle preview pdf
    const handlePreviewPDF = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        let billing_address;

        if (invoiceeIs === 'client') {
            billing_address =
                (selectedClient?.billing_address ? `${selectedClient?.billing_address}, <br /> ` : '') +
                (selectedClient?.billing_city ? `${selectedClient?.billing_city},  <br />` : '') +
                (selectedClient?.billing_postal_code ? `${selectedClient?.billing_postal_code} ` : '') +
                (selectedClient?.billing_state ? `${selectedClient?.billing_state}  <br /> ` : '') +
                (selectedClient?.billing_country ? `${selectedClient?.billing_country} ` : '');
        } else {
            billing_address =
                (selectedMatter?.billing_address ? `${selectedMatter?.billing_address},  <br />` : '') +
                (selectedMatter?.billing_city ? `${selectedMatter?.billing_city},  <br />` : '') +
                (selectedMatter?.billing_postal ? `${selectedMatter?.billing_postal}  ` : '') +
                (selectedMatter?.billing_state ? `${selectedMatter?.billing_state} <br />` : '') +
                (selectedMatter?.billing_country ? `${selectedMatter?.billing_country} ` : '');
        }

        let payload = {
            signature: defultInvoiceData?.signature,
            headerDetails: defultInvoiceData?.headerDetails,
            footerDetails: defultInvoiceData?.footerDetails,
            otherDetails: {
                invoiceNumber: id ? invoiceData?.invoice_id : `${invoiceNumbertype?.prefix}${invoiceNumbertype?.next_number}`,
                invoideDate: invoiceData?.invoice_date,
                terms: `Due on Receipt`,
                // ${moment(invoiceData?.due_date, 'YYYY-MM-DD').diff(
                //     moment(invoiceData?.invoice_date, 'YYYY-MM-DD'),
                //     'days'
                // )}`
                dueDate: invoiceData?.due_date,
                billToName: invoiceData?.billToName,
                billing_address: billing_address,
                business_unit: userDetails?.organisation?.address,
                subject: invoiceData?.subject,
                place_of_supply: invoiceData?.place_of_supply,
                po_number: invoiceData?.po_number,
            },
            billItems: JSON.parse(JSON.stringify(billItems)).map((item, i) => {
                return {
                    ...item,
                    amount: formatNumberIndian(item.amount)
                };
            }),
            billTotal: formatNumberIndian(billItems.reduce((total, item) => total + parseInt(item.amount), 0).toFixed(2)),
            billTotalInWords:
                invoiceData?.currency === 'INR'
                    ? 'Indian Rupee' + ' ' + numberToword(billItems.reduce((total, item) => total + parseInt(item.amount), 0))
                    : 'US Dollar' + ' ' + numberToword(billItems.reduce((total, item) => total + parseInt(item.amount), 0)),
            amountDue: formatNumberIndian(billItems.reduce((total, item) => total + parseInt(item.amount), 0).toFixed(2)),
            billCurrency: invoiceData?.currency === 'INR' ? '₹' : '$',
            remark: 'Thanks for your Business.'
        };

        let res = await genratePdf(payload);

        if (res.data) {
            setPDFData(res.data);
            setIsLoading(false);
            setPreviewInvoice(true);
        } else {
            setIsLoading(false);
        }
    };

    const deleteExpanseById = async (id, item) => {
        await deleteExpanse(userDetails?.organisation?.id, id).then((res) => {
            if (res) {
                getExpanseData();
                setToastData({
                    open: true,
                    status: 'success',
                    message: 'Expanse Deleted Successfully'
                });
            } else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || 'Something went wrong'
                });
            }
        });
    };

    // handle handleRaiseInvoice
    const handleRaiseInvoice = async () => {
        let billing_address;

        // if (invoiceeIs === 'client') {
        //     billing_address =
        //         (selectedClient?.billing_address ? `${selectedClient?.billing_address}, ` : '') +
        //         (selectedClient?.billing_city ? `${selectedClient?.billing_city}, ` : '') +
        //         (selectedClient?.billing_postal_code ? `${selectedClient?.billing_postal_code}, ` : '') +
        //         (selectedClient?.billing_state ? `${selectedClient?.billing_state}, ` : '') +
        //         (selectedClient?.billing_country ? `${selectedClient?.billing_country} ` : '');
        // }
        // else {
        //     billing_address =
        //         (selectedMatter?.billing_address ? `${selectedMatter?.billing_address}, ` : '') +
        //         (selectedMatter?.billing_city ? `${selectedMatter?.billing_city}, ` : '') +
        //         (selectedMatter?.billing_state ? `${selectedMatter?.billing_state}, ` : '') +
        //         (selectedMatter?.billing_postal ? `${selectedMatter?.billing_postal}, ` : '') +
        //         (selectedMatter?.billing_country ? `${selectedMatter?.billing_country} ` : '');
        // }
        if (invoiceeIs === 'client') {
            billing_address =
                (selectedClient?.billing_address ? `${selectedClient?.billing_address}, <br /> ` : '') +
                (selectedClient?.billing_city ? `${selectedClient?.billing_city},  <br />` : '') +
                (selectedClient?.billing_postal_code ? `${selectedClient?.billing_postal_code} ` : '') +
                (selectedClient?.billing_state ? `${selectedClient?.billing_state}  <br /> ` : '') +
                (selectedClient?.billing_country ? `${selectedClient?.billing_country} ` : '');
        } else {
            billing_address =
                (selectedMatter?.billing_address ? `${selectedMatter?.billing_address},  <br />` : '') +
                (selectedMatter?.billing_city ? `${selectedMatter?.billing_city},  <br />` : '') +
                (selectedMatter?.billing_postal ? `${selectedMatter?.billing_postal}  ` : '') +
                (selectedMatter?.billing_state ? `${selectedMatter?.billing_state} <br />` : '') +
                (selectedMatter?.billing_country ? `${selectedMatter?.billing_country} ` : '');
        }

        setinvoiceData({ ...invoiceData, billing_address: billing_address });

        let payload = {
            invoice_date: invoiceData?.invoice_date,
            invoice_number: id ? invoiceData?.invoice_id : `${invoiceNumbertype.prefix}${invoiceNumbertype.next_number}`,
            line_items: billItems.map((item) => ({
                serial_num: item.sno.toString(),
                fee_type: item.fee_type === 'prof_fee' ? 'Service Fee' : 'Expenses',
                description: item.itemName,
                amount: parseFloat(item.amount) || 0 // Use parseFloat to convert amount to number
            })),
            status: invoiceData?.status,
            billing_terms: 'Net 30',
            due_date: invoiceData?.due_date,
            subject: invoiceData?.subject,
            po_number: invoiceData?.po_number,
            client_id: invoiceData?.client_id,
            matter_id: invoiceData?.matter_id,
            contact_person: (invoiceData?.contact_person === null || invoiceData?.contact_person === 'none') ? '' : invoiceData?.contact_person,
            business_unit: invoiceData?.business_unit,
            currency: invoiceData?.currency,
            invoicee_name: invoiceData?.billToName,
            billing_address: billing_address,
            initiated_by: invoiceData?.initiated_by,
            timesheet_start_date: timeSheetFilterDate.startDate,
            timesheet_end_date: timeSheetFilterDate.endDate,
            place_of_supply: invoiceData?.place_of_supply
            // signature: defultInvoiceData?.signature,
        };

        if (id) {
            await updateInvoice(userDetails?.organisation?.id, id, payload).then((response) => {
                setIsLoading(false);
                setToastData({
                    open: true,
                    status: 'success',
                    message: response?.messages || 'Something went wrong'
                });
                setTimeout(() => {
                    navigate('/dashboard/billing-invoice');
                }, 2000);
            });
        } else {
            await createInvoice(userDetails?.organisation?.id, payload).then((response) => {
                if (response.messages === 'Invoice created.') {
                    setIsLoading(false);
                    setToastData({
                        open: true,
                        status: 'success',
                        message: response?.messages || 'Something went wrong'
                    });
                    setTimeout(() => {
                        navigate('/dashboard/billing-invoice');
                    }, 2000);
                } else {
                    setToastData({
                        open: true,
                        status: 'error',
                        message: response.response.data.message || 'Something went wrong'
                    });
                }
            });
        }
    };

    const getInvoiceeName = (matter_id) => {
        const data = (allClient && allClient?.filter((item) => item.id === invoiceData.client_id)) || [];
        setSelectedClient(data[0]);
        setContactData(data[0]?.contact_person);
        let options = [...data];
        if (allMatterList && allMatterList?.length > 0) {
            let matterData = JSON?.parse(JSON?.stringify(allMatterList)).filter(
                (item) => item.id === matter_id && item?.billing_type === 'thirdparty'
            );

            setSelectedMatter(matterData[0]);
            if (invoiceData?.billToName && matterData) {
                if (invoiceData?.billToName === matterData[0]?.billing_contact_name) {
                    setInvoiceeIs('matter');
                } else {
                    setInvoiceeIs('client');
                }
            }
            matterData = matterData.map((item) => {
                return {
                    matter_id: item?.id,
                    name: item?.billing_contact_name,
                    value: item?.billing_contact_name,
                    billing_state: item?.billing_state
                };
            });
            if (matterData && matterData.length > 0) {
                options = [...options, ...matterData];
            }
        }
        setInvoiceeName(options);
    };
    console.log('invoiceData', invoiceData);
    return (
        <>
            {IsLoading && <Loader />}
            {!previewInvoice ? (
                <Grid container direction={'row'} spacing={2}>
                    <Grid item xs={8}>
                        <Card sx={{ p: 2 }}>
                            <form onSubmit={handlePreviewPDF} autoComplete="off">
                                <Stack mb={2} px={1}>
                                    <h5>Invoices</h5>
                                </Stack>
                                <Grid container sx={{ maxWidth: '550px' }}>
                                    <Grid item xs={6}>
                                        <Item>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="client_id">Client Name</InputLabel>
                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    value={invoiceData?.client_id}
                                                    displayEmpty
                                                    label="Client Name "
                                                    name="client_id"
                                                    onChange={(e) => handleChange(e.target.value, 'client_id')}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    required
                                                >
                                                    {allClient?.map((client, i) => {
                                                        return (
                                                            <MenuItem value={client.id} key={i}>
                                                                {client?.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}></Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="matter">Select Matter</InputLabel>
                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    value={invoiceData?.matter_id || ''}
                                                    displayEmpty
                                                    label="Select Matter "
                                                    name="matter"
                                                    onChange={(e) => handleChange(e.target.value, 'matter_id')}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    required
                                                    disabled={!invoiceData.client_id}
                                                >
                                                    {allMatterList?.map((client, i) => {
                                                        return (
                                                            <MenuItem value={client.id} key={i}>
                                                                {client?.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                autoComplete="PO Number"
                                                id="PO Number"
                                                type="text"
                                                label="PO Number"
                                                value={invoiceData?.po_number ? invoiceData?.po_number : ''}
                                                onChange={(e) => handleChange(e.target.value, 'po_number')}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                autoComplete="invoice_id"
                                                id="invoice_id"
                                                disabled={id ? true : false}
                                                type="text"
                                                label="Invoice Id"
                                                value={
                                                    id
                                                        ? invoiceData?.invoice_id
                                                        : `${invoiceNumbertype?.prefix}${invoiceNumbertype?.next_number}`
                                                }
                                                required
                                                onClick={() => {
                                                    setPopupData({
                                                        title: '',
                                                        show: !popupData.show,
                                                        text: ''
                                                    });
                                                }}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Item>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="billToName">Invoicee Name</InputLabel>
                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    value={invoiceData?.billToName || ''}
                                                    displayEmpty
                                                    label="Invoicee Name"
                                                    name="billToName"
                                                    onChange={(e) => handleChange(e.target.value, 'billToName')}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    disabled={!invoiceData.matter_id}
                                                >
                                                    {(invoiceeName?.length > 0 &&
                                                        invoiceeName?.map((item, i) => {
                                                            return (
                                                                <MenuItem value={item?.name} key={i}>
                                                                    {item?.name}
                                                                </MenuItem>
                                                            );
                                                        })) ||
                                                        []}

                                                    {/* {invoiceeName?.length > 0 ? (
                                                        invoiceeName?.map((item, i) => {
                                                            return (
                                                                <MenuItem value={item?.name} key={i}>
                                                                    {item?.name}
                                                                </MenuItem>
                                                            );
                                                        }) || []
                                                    ) : id && invoiceData?.billToName ? (
                                                        <MenuItem value={invoiceData?.billToName}>{invoiceData?.billToName}</MenuItem>
                                                        
                                                    ) : null} */}
                                                </Select>
                                            </FormControl>

                                            {/* 
                                            <TextField
                                                size="small"
                                                fullWidth
                                                autoComplete="invoicee_name"
                                                id="invoicee_name"
                                                type="text"
                                                label="invoicee_name"
                                                value={invoiceData?.billToName}
                                                onChange={(e) => handleChange(e.target.value, 'billToName')}
                                                required
                                            /> */}
                                        </Item>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Item>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    size="small"
                                                    fullWidth
                                                    label="Date"
                                                    inputFormat="YYYY-MM-DD"
                                                    value={invoiceData?.invoice_date}
                                                    onChange={(e) => handleDateChange(e, 'invoice_date')}
                                                    required
                                                    renderInput={(params) => <TextField style={{ width: '100%' }} {...params} required />}
                                                />
                                            </LocalizationProvider>
                                        </Item>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Item>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    size="small"
                                                    fullWidth
                                                    label="Due Date"
                                                    inputFormat="YYYY-MM-DD"
                                                    value={invoiceData?.due_date}
                                                    onChange={(e) => handleDateChange(e, 'due_date')}
                                                    required
                                                    renderInput={(params) => <TextField style={{ width: '100%' }} {...params} required />}
                                                />
                                            </LocalizationProvider>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                autoComplete="subject"
                                                id="subject"
                                                type="text"
                                                label="Subject"
                                                value={invoiceData?.subject}
                                                required
                                                onChange={(e) => handleChange(e.target.value, 'subject')}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="contact_person">Contact Person</InputLabel>
                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    value={invoiceData?.contact_person === '' ? 'none' : invoiceData?.contact_person}
                                                    displayEmpty
                                                    label="Contact Person "
                                                    name="contact_person"
                                                    onChange={(e) => handleChange(e.target.value, 'contact_person')}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    disabled={!invoiceData.matter_id}
                                                >
                                                    <MenuItem value='none'> None </MenuItem>
                                                    {contactData?.length ? (
                                                        contactData?.map((item, i) => {
                                                            return (
                                                                <MenuItem value={item.id} key={i}>
                                                                    {item?.name}
                                                                </MenuItem>
                                                            );
                                                        })
                                                    ) : (
                                                        <MenuItem value={-1} disabled>
                                                            No Data
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Item>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                    <Item>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="contact_person" >Business Unit</InputLabel>
                                            <Select
                                                fullWidth
                                                size="small"
                                                value={invoiceData.business_unit}
                                                displayEmpty
                                                label="Business Unit "
                                                name="business_unit"
                                                onChange={(e) => handleChange(e.target.value, 'business_unit')}
                                                inputProps={{ 'aria-label': 'Without label' }}

                                            >
                                                {contactData?.map((item, i) => {
                                                    return (
                                                        <MenuItem value={item.id} key={i}> {item?.name} </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Item>
                                </Grid> */}
                                    <Grid item xs={12}>
                                        <Item>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                autoComplete="business_unit"
                                                id="business_unit"
                                                type="text"
                                                label="Business Unit"
                                                value={invoiceData?.business_unit ? invoiceData?.business_unit : ''}
                                                onChange={(e) => handleChange(e.target.value, 'business_unit')}
                                                required
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>

                                {/* Invoice Items */}
                                <Stack sx={{ maxWidth: '773px' }} mt={2} fullWidth>
                                    <Stack
                                        sx={{ maxWidth: '773px', backgroundColor: '#EDEDED', borderRadius: '4px' }}
                                        mb={1}
                                        alignContent={'center'}
                                        p={1}
                                        px={2}
                                    >
                                        <h6 style={{ marginBottom: '0' }}> Invoice Items </h6>
                                    </Stack>
                                    <Stack>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '130px' }}>
                                                        <Item sx={{ color: '#404040' }}>Fee Type</Item>
                                                    </th>
                                                    <th>
                                                        <Item sx={{ color: '#404040' }}>Item Name/ID</Item>
                                                    </th>

                                                    <th style={{ width: '130px' }}>
                                                        <Item sx={{ color: '#404040' }}>Amount</Item>
                                                    </th>
                                                    <th style={{ width: '50px' }}> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {console.log('billItems', billItems)}
                                                {billItems.map((item, i) => {
                                                    return (
                                                        <>
                                                            <tr key={i}>
                                                                <td>
                                                                    <Item>
                                                                        <FormControl fullWidth size="small">
                                                                            <Select
                                                                                fullWidth
                                                                                size="small"
                                                                                value={item.fee_type}
                                                                                displayEmpty
                                                                                name="fee_type"
                                                                                onChange={(e) =>
                                                                                    handleInputChange(item.sno, e.target.value, 'fee_type')
                                                                                }
                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                required
                                                                            >
                                                                                {feeType?.map((feeTypeitem, i) => {
                                                                                    return (
                                                                                        <MenuItem value={feeTypeitem.value} key={i}>
                                                                                            {feeTypeitem?.name}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Item>
                                                                </td>
                                                                <td>
                                                                    <Item>
                                                                        <TextField
                                                                            size="small"
                                                                            fullWidth
                                                                            autoComplete="itemName"
                                                                            id="itemName"
                                                                            type="text"
                                                                            placeholder={
                                                                                item?.fee_type === 'expenses'
                                                                                    ? 'Miscellaneous'
                                                                                    : 'Professional Services Provided'
                                                                            }
                                                                            value={item?.itemName}
                                                                            InputLabelProps={{ 'aria-label': 'Without label' }}
                                                                            onChange={(e) =>
                                                                                handleInputChange(item.sno, e.target.value, 'itemName')
                                                                            }
                                                                            required
                                                                        />
                                                                    </Item>
                                                                </td>
                                                                <td>
                                                                    <Item>
                                                                        <TextField
                                                                            size="small"
                                                                            fullWidth
                                                                            autoComplete="amount"
                                                                            id="amount"
                                                                            type="number"
                                                                            // label='Amount'
                                                                            value={item?.amount}
                                                                            placeholder="Amount"
                                                                            InputLabelProps={{ 'aria-label': 'Without label' }}
                                                                            onChange={(e) =>
                                                                                handleInputChange(item.sno, e.target.value, 'amount')
                                                                            }
                                                                            required
                                                                        />
                                                                    </Item>
                                                                </td>
                                                                <td>
                                                                    {item.sno > 1 ? (
                                                                        <IconButton onClick={() => handleDeleteItem(item.sno)}>
                                                                            <Iconify
                                                                                icon="eva:trash-2-outline"
                                                                                sx={{ color: '#FF5353' }}
                                                                                width={24}
                                                                                height={24}
                                                                            />
                                                                        </IconButton>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <Stack alignItems={'flex-start'}>
                                            <Button
                                                variant="text"
                                                startIcon={<Iconify icon="eva:plus-fill" />}
                                                onClick={() => addNewItem()}
                                            >
                                                Add New Row
                                            </Button>
                                        </Stack>
                                    </Stack>

                                    {/* Invoice Preference */}
                                    <Stack>
                                        <Stack sx={{ maxWidth: '773px' }} mt={5} alignContent={'center'} p={1}>
                                            <h6 style={{ marginBottom: '0' }}> Invoice Preference </h6>
                                        </Stack>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Item>
                                                    {console.log('userList', userList)}
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="contact_person">Initiated By</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            size="small"
                                                            value={invoiceData.initiated_by}
                                                            displayEmpty
                                                            label="Initiated By "
                                                            name="initiated_by"
                                                            onChange={(e) => handleChange(e.target.value, 'initiated_by')}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            required
                                                        >
                                                            {userList?.map((item, i) => {
                                                                return (
                                                                    <MenuItem value={item.id} key={i}>
                                                                        {item?.first_name} {item?.last_name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Item>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="Currency">Currency</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            size="small"
                                                            // value={invoiceData.currency}
                                                            displayEmpty
                                                            label="Currency"
                                                            name="Currency"
                                                            onChange={(e) => handleChange(e.target.value, 'Currency')}
                                                            value={invoiceData.currency}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            disabled
                                                        >
                                                            {[
                                                                { id: 'INR', name: 'INR' },
                                                                { id: 'USD', name: 'USD' }
                                                            ]?.map((item, i) => {
                                                                return (
                                                                    <MenuItem value={item.id} key={i}>
                                                                        {item?.name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Item>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="Invoice Type">Invoice Type</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            size="small"
                                                            value={labelValueObject.length ? labelValueObject[0]?.value : ''}
                                                            displayEmpty
                                                            label="Invoice Type "
                                                            name="Invoice Type"
                                                            onChange={(e) => handleChange(e.target.value, 'Invoice_Type')}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            disabled
                                                        >
                                                            {console.log('labelValueObject', labelValueObject[0]?.value)}
                                                            {labelValueObject?.map((item, i) => {
                                                                return (
                                                                    <MenuItem value={item.value} key={i}>
                                                                        {item?.label}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                            {/* 
                                                            <MenuItem value={defultInvoiceData?.templateName}>
                                                                {defultInvoiceData?.templateName}
                                                            </MenuItem> */}
                                                        </Select>
                                                    </FormControl>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction={'row'}
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    gap={2}
                                    pt={2}
                                    mt={2}
                                    style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.22) 0px -11px 6px -13px' }}
                                >
                                    <Item>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                size="small"
                                                sx={{ width: '200px' }}
                                                // type="text"
                                                // label="Category"
                                                variant="outlined"
                                                displayEmpty
                                                placeholder="Select Status"
                                                defaultValue={'Draft'}
                                                name=""
                                                // onChange={(e) => handleChange(e.target.value, 'expense_category')}
                                                // value={formData?.expense_category}

                                                value={invoiceData.status}
                                                onChange={(e) => handleChange(e.target.value, 'status')}
                                                fullWidth
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                required
                                            >
                                                <MenuItem value="Raise">Raise</MenuItem>
                                                <MenuItem value="Paid">Paid</MenuItem>
                                                <MenuItem value="Partially Paid">Partially Paid</MenuItem>
                                                <MenuItem value="Cancelled">Cancelled</MenuItem>
                                                <MenuItem value="Draft">Draft</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Item>
                                    <Stack direction={'row'} alignItems="center" gap={2} style={{ maxWidth: '250px', width: '100%' }}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            onClick={() => {
                                                navigate('/dashboard/billing-invoice');
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            disabled={!invoiceData.matter_id}
                                        >
                                            Save
                                        </Button>
                                    </Stack>
                                </Stack>
                            </form>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <h6> Select Date: </h6>
                        <Stack direction={'row'} gap={2} py={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    size="small"
                                    fullWidth
                                    label="From"
                                    inputFormat="YYYY-MM-DD"
                                    value={timeSheetFilterDate?.startDate}
                                    onChange={(e) => handleTimeSheetDateChange(e, 'startDate')}
                                    renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                                    disabled={!invoiceData.matter_id}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    size="small"
                                    fullWidth
                                    label="To"
                                    inputFormat="YYYY-MM-DD"
                                    value={timeSheetFilterDate?.endDate}
                                    onChange={(e) => handleTimeSheetDateChange(e, 'endDate')}
                                    renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                                    disabled={!invoiceData.matter_id}
                                />
                            </LocalizationProvider>
                        </Stack>

                        <Stack mb={2}>
                            <h6> Timesheet </h6>
                            <Card>

                                <div style={{ overflow: 'auto', maxHeight: '500px' }}>
                                    <Table>
                                        <TableHead sx={{ position: 'sticky', top: '0', zIndex: 1, backgroundColor: '#fff' }}>
                                            <TableRow>
                                                <TableCell> User Name </TableCell>
                                                <TableCell> BT </TableCell>
                                                <TableCell align="right"> Amount </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {timeSheetData?.data && Object.keys(timeSheetData?.data?.timesheet_detail || {})?.length === 0 ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center">
                                                        No Data Found
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {/* {console.log('timeSheetData', Object.keys(timeSheetData?.data))} */}
                                                {timeSheetData?.data?.timesheet_detail &&
                                                    Object?.keys(timeSheetData?.data?.timesheet_detail || {}).map((item, i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                <TableCell>
                                                                    {timeSheetData?.data?.timesheet_detail?.[item]?.full_name}
                                                                </TableCell>
                                                                <TableCell style={{ color: '#00781A' }}>
                                                                    {secondsToHms(
                                                                        timeSheetData?.data?.timesheet_detail?.[item]?.total_time
                                                                    )}
                                                                    {/* {timeSheetData?.data?.timesheet_detail?.[item]?.total_time} */}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                ₹{timeSheetData?.data?.timesheet_detail?.[item]?.bill}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                <TableRow>
                                                    <TableCell>
                                                        <Button onClick={() => navigate('/dashboard/timesheet')} variant="contained">
                                                            View All
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell style={{ color: '#00781A' }}>
                                                        {secondsToHms(timeSheetData?.data?.total_time)}

                                                        {/* {timeSheetData?.data?.total_time} */}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        ₹
                                                        {timeSheetData?.data?.total_amount
                                                            ? Number(timeSheetData?.data?.total_amount).toFixed(2)
                                                            : 0}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </div>
                            </Card>
                        </Stack>
                        <Stack>
                            <h6> Expense </h6>
                            <Card>
                                <Stack p={2}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Iconify icon="eva:plus-fill" />}
                                        fullWidth
                                        onClick={() => setShowNewExpanseModal(true)}
                                        disabled={!invoiceData.matter_id}
                                    >
                                        Add Expenses
                                    </Button>
                                </Stack>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={'30px'} />
                                            <TableCell> Expense Type </TableCell>
                                            <TableCell align="right"> Amount </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {addedExpanseData?.data?.length > 0 ? (
                                            <>
                                                {addedExpanseData?.data?.map((item, i) => {
                                                    return (
                                                        <TableRow key={i} className="expanse-table-invoice">
                                                            <TableCell sx={{ p: '0 ' }}>
                                                                <IconButton
                                                                    sx={{ p: '0' }}
                                                                    aria-label="delete"
                                                                    onClick={() => {
                                                                        deleteExpanseById(item.id, item);
                                                                    }}
                                                                >
                                                                    <IoMdClose color="red" />
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell> {item?.expense_category} </TableCell>
                                                            <TableCell align="right"> ₹ {item?.amount} </TableCell>
                                                        </TableRow>
                                                    );
                                                })}

                                                <TableRow>
                                                    <TableCell> </TableCell>
                                                    <TableCell>
                                                        <strong> Total </strong>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <strong>
                                                            ₹ {addedExpanseData?.data?.reduce((acc, item) => acc + item.amount, 0)}
                                                        </strong>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={3} align="center">
                                                    No Data Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Card>
                        </Stack>
                    </Grid>
                </Grid>
            ) : (
                // </Stack >
                <Stack fullWidth sx={{ height: '85vh' }}>
                    <embed src={`data:application/pdf;base64,${pdfData}`} style={{ height: '100%' }} />
                    <Stack direction={'row'} alignItems="center" justifyContent={'flex-end'} gap={2} mt={2}>
                        <Button variant="outlined" color="primary" onClick={() => setPreviewInvoice(false)}>
                            Back
                        </Button>
                        <Button variant="contained" color="primary" type="submit" onClick={() => handleRaiseInvoice()}>
                            {invoiceData?.status} Invoice
                        </Button>
                    </Stack>
                </Stack>
            )}

            {/* Invoice Number popup */}
            <ModalComponent width="500" open={popupData.show} handleClose={() => handleClosePopup()}>
                <Stack mb={2}>
                    <h4>
                        {/* {popupData.title} */}
                        Invoice Number
                    </h4>
                    <p>Your invoice numbers are set on auto-generate mode to save your time. Are you sure about changing this seting?</p>
                </Stack>
                <Stack style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="auto"
                            name="radio-buttons-group"
                            onChange={(e) => setInvoiceNumbertype({ ...invoiceNumbertype, invoiceNumber: e.target.value })}
                        >
                            <FormControlLabel
                                value="auto"
                                control={<Radio sx={{ color: '#005287 !important' }} />}
                                label="Continue auto-generating invoice numbers"
                            />

                            {invoiceNumbertype.invoiceNumber === 'auto' && (
                                <Stack direction={'row'}>
                                    <FormControl sx={{ width: '100px' }}>
                                        <Item>
                                            <label> Prefix </label>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                autoComplete="invoicee_name"
                                                id="invoicee_name"
                                                type="text"
                                                value="ASL2023-24/"
                                                disabled
                                            />
                                        </Item>
                                    </FormControl>
                                    <FormControl>
                                        <Item>
                                            <label> Next Number </label>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                autoComplete="invoicee_name"
                                                id="invoicee_name"
                                                type="number"
                                                value={invoiceNumbertype?.next_number}
                                                onChange={(e) =>
                                                    setInvoiceNumbertype({ ...invoiceNumbertype, next_number: e.target.value })
                                                }
                                            />
                                        </Item>
                                    </FormControl>
                                </Stack>
                            )}

                            <FormControlLabel
                                value="manual"
                                control={<Radio sx={{ color: '#005287 !important' }} />}
                                label="I will add them manually each time"
                            />

                            {invoiceNumbertype?.invoiceNumber === 'manual' && (
                                <Stack direction={'row'}>
                                    <FormControl sx={{ width: '100px' }}>
                                        <Item>
                                            <label> Prefix </label>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                autoComplete="prefix"
                                                id="prefix"
                                                type="text"
                                                value={invoiceNumbertype?.prefix}
                                                onChange={(e) => setInvoiceNumbertype({ ...invoiceNumbertype, prefix: e.target.value })}
                                            />
                                        </Item>
                                    </FormControl>
                                    <FormControl>
                                        <Item>
                                            <label> Next Number </label>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                autoComplete="next_number"
                                                id="next_number"
                                                type="number"
                                                value={invoiceNumbertype?.next_number}
                                                onChange={(e) =>
                                                    setInvoiceNumbertype({ ...invoiceNumbertype, next_number: e.target.value })
                                                }
                                            />
                                        </Item>
                                    </FormControl>
                                </Stack>
                            )}
                        </RadioGroup>
                    </FormControl>
                </Stack>

                <Stack direction={'row'} alignItems="center" gap={2} mt={2} style={{ maxWidth: '250px' }}>
                    <Button variant="contained" fullWidth color="primary" type="submit" onClick={() => handleClosePopup()}>
                        Save
                    </Button>
                    <Button variant="outlined" onClick={() => handleClosePopup()} color="primary" fullWidth>
                        Cancel
                    </Button>
                </Stack>
            </ModalComponent>

            <AddExpanseModal
                invoiceData={invoiceData}
                open={showNewExpanseModal}
                handleClose={() => setShowNewExpanseModal(false)}
                getExpanseData={getExpanseData}
                addedExpanseData={addedExpanseData}
            />

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    );
};

export default CreateInvoice;
