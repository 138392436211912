/* eslint-disable arrow-body-style */
// material-ui
// import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/lexops_logo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    // const theme = useTheme();
    return (

        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */

        <a href={localStorage.getItem('token') ? '/dashboard/my-subscriptions' : '/'} style={{ display: "inline-block" }}>
            <img src={logo} alt="Kronicle" width="120" />
        </a>

    );
};

export default Logo;
