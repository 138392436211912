import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Tab } from '@mui/material';
import React from 'react';
import AdminDepartmentMaster from '../AdminDepartmentMaster';
import AdminPracticeAreaMaster from '../AdminPracticeAreaMaster';

const AdminSetup = () => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card padding={5}>
            <Box mt={2} sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" >
                            <Tab label="Department" value="1" sx={{ fontSize: '16px' }} />
                            <Tab label="Practice Area" value="2" sx={{ fontSize: '16px' }} />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: '10px' }}>
                        <AdminDepartmentMaster />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: '10px' }}>
                        <AdminPracticeAreaMaster />
                    </TabPanel>
                </TabContext>
            </Box>
        </Card>
    );
};


export default AdminSetup;