import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, TableContainer, TablePagination, TextField, CardContent, Paper, FormControlLabel, Switch, tableCellClasses } from '@mui/material';

import Scrollbar from 'components/Scrollbar';
import { UserListHead, UserListToolbar } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { styled } from '@mui/system';
import Toast from 'components/Toast';
import Iconify from 'components/Iconify';
import { createModule, createScreen, deleteModule, deleteScreen, getAllModule, getAllScreen, updateModule } from 'services/UserAccessMasterService';
import ModuleMoreMenu from './ModuleMoreMenu';
import AssignScreenModal from './AssignScreenModal';
import StyledTableListHead from 'views/StyledTable/StyledTableListHead';
import { CustomIOSSwitch } from 'components/ISOSwitch';
import NewModalComponent from 'components/NewModalComponent';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td ': {
        borderRight: "1px solid #D1D9E0 !important"
    },
    'th ': {
        borderBottom: "1px solid #D1D9E0 !important"
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    '&:last-child': {
        borderRight: '0 !important',
    },
    fontSize: '14px',
    fontWeight: 500,
    color: '#404040',
    padding: '10px !important',

}));


const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'screens', label: 'Screens', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


function ModuleMaster() {

    const [moduleData, SetModuleData] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [createModal, setCreateModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [assignModal, setAssignModal] = useState(false)
    const [moduleId, setModuleId] = useState('')


    const [moduleDataValue, setModuleDataValue] = useState({
        name: '',
        status: 'INACTIVE',
    });
    const [prevmoduleData, setprevModuleData] = useState([])
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    useEffect(() => {
        fetchModuleData()
    }, [])

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - moduleData?.length) : 0;

    const filterCoupon = applySortFilter(moduleData, getComparator(order, orderBy), filterName);

    const isDataNotFound = filterCoupon?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = moduleData?.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setModuleDataValue((prev) => ({ ...prev, [name]: value }));
    };

    const handleSwitch = (name, value) => {
        let status
        if (value) {
            status = 'ACTIVE';
        } else {
            status = 'INACTIVE';
        }
        setModuleDataValue((prev) => ({ ...prev, [name]: status }));
    }



    //fetch all module data
    const fetchModuleData = async () => {
        const res = await getAllModule();
        if (res?.data) {
            SetModuleData(res?.data)
        }
        else {
            SetModuleData([])
        }
    }



    const EditModuleStatus = (id, data) => {
        setIsEdit(true)
        setModuleDataValue(data)
        setprevModuleData(data)
        setCreateModal(true)
    }

    const handelCloseModal = () => {
        setModuleDataValue({
            name: '',
            status: 'INACTIVE',
        })
        setCreateModal(false)
        setIsEdit(false)
        setModuleId('')
        setAssignModal(false)
    }


    const findChangedData = (previousObj, currentObj) => {
        const changedData = {};
        for (const key in previousObj) {
            if (previousObj[key] !== currentObj[key]) {
                changedData[key] = currentObj[key];
            }
        }
        return changedData;
    };


    // handle create update module
    const handleCreateUpdateModule = async (event) => {
        event.preventDefault()
        let payload = {
            ...moduleDataValue,
            status: moduleDataValue?.status
        }

        if (isEdit) {
            const newChangedKeys = findChangedData(prevmoduleData, moduleDataValue);

            const res = await updateModule(moduleDataValue?.id, newChangedKeys);
            if (res?.message === "Module data updated successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchModuleData();
                setIsEdit(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || 'Something went wrong'
                });
            }
        }
        else {
            const res = await createModule(payload);
            if (res.message === "Module data created successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchModuleData();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }
        }
    }


    //handle table toggle 
    const toggleActive = async (e, id) => {
        const tempScreenData = [...moduleData];
        tempScreenData.forEach(async (el) => {
            console.log("ël", el);
            if (el.id === id) {
                if (e.target.checked) {
                    el.status = 'ACTIVE';
                } else {
                    el.status = 'INACTIVE';
                }
                const payload = {
                    status: el.status,
                };
                const res = await updateModule(id, payload);
                console.log(res, 'resData');
                if (res.message === "Module data updated successfully.") {
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.message || 'Something went wrong'
                    });
                    fetchModuleData();
                }
                else {
                    fetchModuleData();
                    setToastData({
                        open: true,
                        status: 'error',
                        message: res?.response?.data?.message || 'Something went wrong'
                    });
                }
            }
        });

    };

    const removeModuleData = async (id) => {
        const res = await deleteModule(id);
        if (res.message === "Module deleted successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchModuleData();
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || res?.message || 'Something went wrong'
            });
        }
    }


    const AssignScreen = (id) => {
        setAssignModal(true)
        setModuleId(id)
    }




    return (
        <>
            {IsLoading && <Loader />}

            {!assignModal ?
                <>
                    <Card padding={5}>
                        <Stack className='breadcrubs' justifyItems={'flex-start'} alignItems={'flex-start'}>
                            <h5> Module Master </h5>

                            <Stack mt={2} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'} sx={{ width: '100%' }}>
                                <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                                <button className='blue_btn' onClick={() => setCreateModal(!createModal)} >
                                    Create Module
                                </button>

                            </Stack>
                        </Stack>


                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 400, marginTop: "20px" }}>
                                <Table
                                    sx={{
                                        background: 'white',
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        border: '1px solid #D1D9E0',
                                        borderCollapse: 'unset',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <StyledTableListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={moduleData?.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />

                                    <TableBody>
                                        {filterCoupon?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                            const { id, name, status } = row;
                                            const isItemSelected = selected.indexOf(name) !== -1;
                                            return (
                                                <StyledTableRow
                                                    // hover
                                                    // key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <StyledTableCell align="left"> {name ? name : '-'} </StyledTableCell>
                                                    <StyledTableCell align="left" >
                                                        <CustomIOSSwitch
                                                            handleSwitch={(e) => toggleActive(e, id)}
                                                            status={status}
                                                        />
                                                    </StyledTableCell>

                                                    <StyledTableCell align="left" >
                                                        {/* {!row.screen.length ? */}
                                                        <Button
                                                            // startIcon={
                                                            //     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            //         <path d="M2.5 10C2.5 9.77 2.68667 9.58333 2.91667 9.58333H9.58333V2.91667C9.58333 2.68667 9.77 2.5 10 2.5C10.23 2.5 10.4167 2.68667 10.4167 2.91667V9.58333H17.0833C17.3133 9.58333 17.5 9.77 17.5 10C17.5 10.23 17.3133 10.4167 17.0833 10.4167H10.4167V17.0833C10.4167 17.3133 10.23 17.5 10 17.5C9.77 17.5 9.58333 17.3133 9.58333 17.0833V10.4167H2.91667C2.68667 10.4167 2.5 10.23 2.5 10Z" fill="#216DDE" />
                                                            //     </svg>
                                                            // }
                                                            onClick={() => AssignScreen(id)}
                                                            color="primary"
                                                        >
                                                            Assign/View Screeen
                                                        </Button>

                                                        {/* :
                                                            <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                                sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                                onClick={() => AssignScreen(id)}
                                                            >
                                                                Screens Assigned
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_1839_74338)">
                                                                        <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_1839_74338">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </Stack>
                                                        } */}

                                                    </StyledTableCell>

                                                    <StyledTableCell align="center" >
                                                        <ModuleMoreMenu
                                                            onEdit={EditModuleStatus}
                                                            id={id}
                                                            data={row}
                                                            removeData={removeModuleData}
                                                        />
                                                    </StyledTableCell>

                                                </StyledTableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <StyledTableRow style={{ height: 53 * emptyRows }}>
                                                <StyledTableCell colSpan={13} />
                                            </StyledTableRow>
                                        )}
                                    </TableBody>
                                    {isDataNotFound && (
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={moduleData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>


                    <NewModalComponent
                        width="100%"
                        maxWidth="350px"
                        open={createModal}
                        handleClose={() => handelCloseModal()}
                    >
                        <Card>
                            <Stack px={3} pt={3} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                                <Stack mt={1} sx={{ width: '100%' }} alignContent={'flex-start'}>
                                    <h4> {isEdit ? 'Update' : 'Create'} Module </h4>
                                </Stack>

                                <form onSubmit={handleCreateUpdateModule}>

                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        label="Name"
                                        variant="outlined"
                                        value={moduleDataValue?.name}
                                        name="name"
                                        onChange={handleChange}
                                        required
                                    />

                                    <Stack direction="row"
                                        spacing={2}
                                        mb={2}
                                        mt={2}
                                        sx={{
                                            border: ' 1px solid #D1D9E0',
                                            borderRadius: '10px',
                                            padding: '10px 14px'
                                        }}
                                        alignItems='center'
                                        justifyContent={'space-between'}
                                    >
                                        <label style={{ color: '#606060', fontWeight: '400' }}> Status </label>

                                        <CustomIOSSwitch
                                            handleSwitch={(e) => handleSwitch('status', e.target.checked)}
                                            status={moduleDataValue?.status}
                                        />
                                    </Stack>


                                    <Stack
                                        className='updatelicense_btn'
                                        flexDirection={'row'}
                                        justifyContent={'flex-end'}
                                        gap={1} style={{ padding: "14px 0", paddingTop: "0", width: "100%" }}>
                                        <button className='outline_btn' onClick={handelCloseModal}>
                                            Cancel
                                        </button>
                                        <button
                                            className='blue_btn'
                                            sx={{ width: "100%" }}
                                            type='submit'
                                        >
                                            {isEdit ? "Update" : "Create"}
                                        </button>
                                    </Stack>

                                </form>
                            </Stack>
                        </Card>
                    </NewModalComponent >
                </>

                :

                <AssignScreenModal moduleId={moduleId} open={assignModal} handleClose={() => handelCloseModal()} />
            }

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />

        </>

    )
}

export default ModuleMaster