/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';
// material
import { Box, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { styled } from '@mui/system';

// ----------------------------------------------------------------------

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)'
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    borderRight: "1px solid #D1D9E0 !important",
    borderBottom: "1px solid #D1D9E0 !important",
    fontSize: '14px',
    fontWeight: 700,
    color: '#303030',
    backgroundColor: '#F8F9FA',
    padding: '10px !important',
    '&:last-child': {
        width: 'auto',
        border: 0,
        borderLeft: '0 !important',
        borderRight: '0 !important',
    },
}));


DataRoleStyledTableListHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    // rowCount: PropTypes.number,
    headLabel: PropTypes.array,
    // numSelected: PropTypes.number,
    onRequestSort: PropTypes.func
    // onSelectAllClick: PropTypes.func,
};



export default function DataRoleStyledTableListHead({
    order,
    rowlist,
    orderBy,
    // rowCount,
    headLabel,
    // numSelected,
    onRequestSort
    // onSelectAllClick,
}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={{ position: "sticky", top: "0", zIndex: 1, backgroundColor: "#fff" }}>
            <TableRow >
                {console.log("rowlist", rowlist)}
                <StyledTableCell> Data Role Name </StyledTableCell>
                <StyledTableCell> Status </StyledTableCell>
                <StyledTableCell> created on </StyledTableCell>
                {rowlist?.includes('client_matter') && <StyledTableCell> Client & matter </StyledTableCell>}
                {rowlist?.includes('department') && <StyledTableCell> Department </StyledTableCell>}
                {rowlist?.includes('designation') && <StyledTableCell> Designation </StyledTableCell>}
                {rowlist?.includes('practise_area') && <StyledTableCell> Practice Area </StyledTableCell>}
                <StyledTableCell> Modules </StyledTableCell>
                <StyledTableCell> Assignee </StyledTableCell>
                <StyledTableCell>  </StyledTableCell>
            </TableRow>
        </TableHead>
    );
}
