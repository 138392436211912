import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import clientMatterReducer from './clientMatterReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({ customization: customizationReducer, clientMatter: clientMatterReducer });

export default reducer;
