import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useAccount } from 'context/AccountProvider';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();
    const { userDetails } = useAccount();

    let UserAcess = useSelector((e) => e?.customization?.userAccess)

    // const uniqueScreens = [...new Set(UserAcess)];


    // console.log("mmmmm", UserAcess, filteredObjects);

    let individualScreen = ['my-subscriptions', 'product-usage-time', 'clients', 'matters', 'timesheet', 'new-timesheet', 'my-access'];
    let orgAdminScreen = ['my-subscriptions', 'my-invoices', 'my-organization', 'organization-members', 'my-invoice-template', 'setup', 'clients', 'matters', 'timesheet', 'new-timesheet', 'expense-masterdata', 'screen-role-master', 'product-usage-time'];
    let orgUserScreen = ['my-organization'];


    // let filteredObjects;

    const handleFilterScreen = (data) => {
        debugger
        let filteredData = item?.children?.map(item => {
            // Check if the item's `id` is in the filter criteria
            if (data.includes(item?.id)) {
                // If the item has children, filter them based on the provided `data`
                if (item.children) {
                    item.children = item?.children?.filter(child => data.includes(child.id));
                }
                return item;
            }
            return null;
        }).filter(item => item !== null); // Remove items that did not match the filter

        console.log("filteredData", filteredData);

        debugger
        return filteredData
    };




    let menuItemsChildren = [];
    if (Object.keys(userDetails).length) {


        if (userDetails.user_type !== 'internal') {
            console.log("allScreen", item.children);
            // const filterMenu = item.children.filter(
            //     (j) =>
            //         (j.id === 'select-plan' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
            //         j.id === 'my-subscriptions' ||
            //         // j.id === 'new-subscriptions' ||
            //         (j.id === 'my-organization' && userDetails.user_type === 'organisation') ||
            //         j.id === 'product-usage-time' ||
            //         (j.id === 'product_assign' && userDetails.user_type === 'organisation') ||
            //         (j.id === 'select-plan' && userDetails.user_type === 'individual') ||
            //         (j.id === 'purchase-plans') ||
            //         j.id === 'clients' ||
            //         j.id === 'matters' ||
            //         j.id === 'timesheet' ||
            //         j.id === 'new-timesheet' ||
            //         j.id === 'clauses' ||
            //         (j.id === 'role-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
            //         (j.id === 'designation-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
            //         (j.id === 'department-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ||
            //         (j.id === 'practice-area-master' && userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user'))
            // );

            // const filterMenu = item.children.filter(
            //     (j) =>
            //         j.id === 'my-subscriptions' ||
            //         (j.id === 'my-organization' && userDetails.user_type === 'organisation') ||
            //         (j.id === 'organization-members' && userDetails.user_type === 'organisation') ||
            //         (j.id === 'my-invoice-template' && userDetails.user_type === 'organisation') ||
            //         j.id === 'product-usage-time' ||
            //         j.id === 'clients' ||
            //         j.id === 'matters' ||
            //         j.id === 'timesheet' ||
            //         j.id === 'new-timesheet' ||
            //         j.id === 'my-access' ||
            //         // j.id === 'my-data-access' ||
            //         (j.id === 'expense-masterdata' && userDetails.user_type === 'organisation' && userDetails?.roles?.some((p) => p !== 'org_user')) ||
            //         (j.id === 'screen-role-master' && userDetails.user_type === 'organisation' && userDetails?.roles?.some((p) => p !== 'org_user'))
            // );

            //userDetails.user_type === 'individual'
            menuItemsChildren =
                (userDetails.user_type === 'organisation' && userDetails.roles.some((p) => p !== 'org_user')) ? handleFilterScreen(orgAdminScreen) : userDetails.user_type === 'individual' ? handleFilterScreen(individualScreen) : handleFilterScreen([...orgUserScreen, ...UserAcess]);


            // menuItemsChildren = (userDetails.user_type === 'organisation' || userDetails.user_type === 'individual') ? [...filterMenu] : [...filteredObjects];
            // menuItemsChildren = (userDetails.user_type !== 'organisation') ? [...filterMenu] : [...filteredObjects];

            // menuItemsChildren = [...filteredObjects];
        } else {
            const filterMenu = item.children.filter(
                (k) => k.id !== 'select-plan' && k.id !== 'purchase-plans' && k.id !== 'my-subscriptions'
                    && k.id !== 'my-organization' && k.id !== 'matters' && k.id !== 'clients' && k.id !== 'timesheet'
                    && k.id !== 'new-timesheet' && k.id !== 'product-usage-time'
                    && k.id !== 'screen-role-master'
                    && k.id !== 'designation-master'
                    && k.id !== 'department-master'
                    && k.id !== 'practice-area-master'
                    && k.id !== 'data-role-master'
                    && k.id !== 'data-role-list'
                    && k.id !== 'sa-setup'
                    && k.id !== 'my-access'
                    && k.id !== 'my-data-access'
                    && k.id !== 'module-master'
                    && k.id !== 'billing-invoice'
                    && userDetails.user_type !== 'organisation'
            );
            menuItemsChildren = [...filterMenu];
        }
    }

    // menu list collapse & items
    // const items = item.children?.map(
    //     (menu) =>
    // menu.role === 'organization' && <NavCollapse key={menu.id} menu={menu} level={1} /> && (
    //     <NavItem key={menu.id} item={menu} level={1} />
    // )
    // switch (menu.type) {
    //     case 'collapse':
    //         return menu.role === 'individual' && <NavCollapse key={menu.id} menu={menu} level={1} />;
    //     case 'item':
    //         return <NavItem key={menu.id} item={menu} level={1} />;
    //     default:
    //         return (
    //             <Typography key={menu.id} variant="h6" color="error" align="center">
    //                 Menu Items Error
    //             </Typography>
    //         );
    // }
    // );


    // menu list collapse & items
    const items = menuItemsChildren?.map((menu) => {
        console.log(menu, 'itemMenu');

        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List sx={{ padding: '0' }}
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom >
                            {/* {item.title} */}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
