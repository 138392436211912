import axios from 'axios';

export const getAllPlans = async () => {
    try {
        const { data } = await axios.get(`plans`);
        return data;
    } catch (err) {
        return err;
    }
};
export const createPlan = async (payload) => {
    try {
        const { data } = await axios.post(`plans`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

export const addProductToPlan = async (planId, productId, payload) => {
    try {
        const { data } = await axios.put(`plans/${planId}/products/${productId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};
export const getProductsLinkToPlan = async (planId) => {
    console.log(planId, 'planId');
    try {
        const { data } = await axios.get(`plans/${planId}/products`);
        return data;
    } catch (err) {
        return err;
    }
};
export const updatePlan = async (planId, payload) => {
    try {
        const { data } = await axios.put(`/plans/${planId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

export const getProductsByPlan = async (id) => {
    try {
        const { data } = await axios.get(`/plans/${id}/products`);
        return data;
    } catch (err) {
        return err;
    }
};



export const uploadImage = async (payload) => {
    const instance = axios.create({
        baseURL: 'https://cms.kronicle.in/api/cms',
    });

    const config = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    try {
        const res = await instance.post(`upload_image`, payload, config);
        return res;
    } catch (error) {
        return error;
    }
};


export const getCart = async () => {
    try {
        const { data } = await axios.get(`cart`);
        return data;
    } catch (err) {
        return err;
    }
};

export const addToCart = async (payload) => {
    try {
        const { data } = await axios.post(`cart`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


export const deleteCart = async (payload) => {
    try {
        const { data } = await axios.delete(`cart`, { data: payload });
        return data;
    } catch (err) {
        return err;
    }
};


export const checkoutApi = async (payload) => {
    try {
        const { data } = await axios.post(`checkout`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


export const verfiyorder = async (payload) => {
    try {
        const { data } = await axios.put(`checkout`, payload);
        return data;
    } catch (err) {
        return err;
    }
};



export const getOrderInvoice = async (id) => {
    try {
        const { data } = await axios.get(`/checkout?order_id=${id}`);
        return data;
    } catch (err) {
        return err;
    }
};