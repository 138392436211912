/* eslint-disable no-use-before-define */

import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Stack } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
    // height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    // padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.MuiOutlinedInput-root': { width: 382, 'background-color': '#fff', },
    '&.Mui-focused': { width: 400 },
    'input': { 'background-color': '#fff' },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderRadius: '10px !important',
        borderColor: `${theme.palette.grey[500_32]} !important`,
        top: "0",
    },
    '& fieldset legend': {
        float: "left",

    }
}));


// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func
};

export default function UserListToolbar({ numSelected, filterName, onFilterName }) {
    return (
        <Stack flexDirection={'row'} gap={2}>
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <SearchStyle
                    size='small'
                    value={filterName}
                    onChange={onFilterName}
                    placeholder="Search..."
                    startAdornment={
                        <InputAdornment position="start">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.854 23.1465L17.426 16.7185C19.033 14.9435 20.022 12.5985 20.022 10.0215C20.021 4.50848 15.535 0.0214844 10.021 0.0214844C4.507 0.0214844 0.0209961 4.50848 0.0209961 10.0215C0.0209961 15.5345 4.507 20.0215 10.021 20.0215C12.598 20.0215 14.943 19.0325 16.718 17.4255L23.146 23.8535C23.244 23.9515 23.372 23.9995 23.5 23.9995C23.628 23.9995 23.756 23.9505 23.854 23.8535C24.049 23.6585 24.049 23.3415 23.854 23.1465ZM1.021 10.0215C1.021 5.05948 5.059 1.02148 10.021 1.02148C14.983 1.02148 19.021 5.05849 19.021 10.0215C19.021 14.9845 14.984 19.0215 10.021 19.0215C5.058 19.0215 1.021 14.9845 1.021 10.0215Z" fill="#303030" />
                            </svg>
                        </InputAdornment>
                    }
                />
            )}

            {/* {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <Iconify icon="ic:round-filter-list" />
                    </IconButton>
                </Tooltip>
            )} */}
        </Stack>
    );
}
