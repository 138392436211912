import React, { useEffect, useState } from 'react';
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import { useAccount } from 'context/AccountProvider';
import { getUserAccessProfileWise } from 'services/UserAccessMasterService';
import { Card, Table, TableRow, TableBody, TableCell, TableContainer, TablePagination, CardContent, Chip, Button, Stack } from '@mui/material';
import { UserListHead, UserListToolbar } from 'sections/@dashboard/user';
import { getUserDataAccessWise, getUserWiseAccessSummary, getUserWiseDataAccess } from 'services/DataRoleService';

const TABLE_HEAD = [
    { id: 'user', label: 'UserName', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'role', label: 'Role', alignRight: false },
    { id: 'module', label: 'Module', alignRight: false },
    { id: 'department', label: 'Department', alignRight: false },
    { id: 'designation', label: 'Designation', alignRight: false },
    { id: 'practice_area', label: 'Practice Area', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.screen?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


function MyDataAccess() {
    const { userDetails } = useAccount()
    const [uniquSummary, setUniquSummary] = useState({})
    const [detailSummary, setDetailSummary] = useState({})


    useEffect(() => {
        if (userDetails?.organisation?.id) {
            fetchMyAccess()
        }
    }, [userDetails?.organisation?.id])

    const fetchMyAccess = async () => {

        const res = await getUserWiseAccessSummary(userDetails.id);
        debugger
        if (res) {
            if (res?.response?.status === 500) {
                setUniquSummary([])
                setDetailSummary([])
            }
            else {
                setUniquSummary(res?.uniue)
                setDetailSummary(res?.summary)
            }
        }
        else {
            setUniquSummary([])
            setDetailSummary([])
        }
    }




    return (
        <>
            <Card padding={5} >
                <Stack className='breadcrubs' mt={2} justifyItems={'flex-start'} alignItems={'flex-start'}>
                    <h5> My Data Access </h5>
                </Stack>
                <Stack mt={2.5}>
                    <ul className='summaryinfo'>
                        <li className='summaryinfo_item'>
                            <div className='summaryinfo_item_left'> <strong> UserName </strong> </div>
                            <div className='summaryinfo_item_right'> milan khunt </div>
                        </li>
                        <li className='summaryinfo_item'>
                            <div className='summaryinfo_item_left'> <strong> Email </strong> </div>
                            <div className='summaryinfo_item_right'> milan khunt </div>
                        </li>
                        <li className='summaryinfo_item'>
                            <div className='summaryinfo_item_left'> <strong> Department </strong> </div>
                            <div className='summaryinfo_item_right'> milan khunt </div>
                        </li>
                        <li className='summaryinfo_item'>
                            <div className='summaryinfo_item_left'> <strong> Designation </strong> </div>
                            <div className='summaryinfo_item_right'> milan khunt </div>
                        </li>
                        <li className='summaryinfo_item'>
                            <div className='summaryinfo_item_left'> <strong> Report to </strong> </div>
                            <div className='summaryinfo_item_right'> milan khunt </div>
                        </li>
                        <li className='summaryinfo_item'>
                            <div className='summaryinfo_item_left'> <strong> Counterparty & project </strong> </div>
                            <div className='summaryinfo_item_right'>

                            </div>
                        </li>
                    </ul>
                </Stack>
            </Card >
        </>
    )
}

export default MyDataAccess