import React, { useState } from 'react'
import {
    Card,
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Avatar,
    CircularProgress,
} from '@mui/material';
import Toast from 'components/Toast';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignScreenToModule, deleteScreenActionFromModule, getAllScreen, getAssignScreenOfModule, getScreenAllAction, screenActionAssignToModule, unAssignScreenFromModule, updateScreenActionToModule } from 'services/UserAccessMasterService';
import Loader from 'ui-component/Loader';
import { getAdminAllModule, getAssignScreenOfAdminModule } from 'services/AdminAcessService';


function AssignModalScreenAction({ fetchOrgRoleData, fetchAdminRoleData, handleClose, roleData, activeScreenRoleID }) {

    console.log("activeRoleData", roleData, roleData.module);

    const [moduleData, setModuleData] = useState([])
    const [moduleloader, setModuleloader] = useState(false)
    const [screenData, setScreenData] = useState([])
    const [screenAction, setScreenActions] = useState([])
    const [assignedScreen, setAssignedScreen] = useState([])
    const [screenId, setScreenId] = useState('')
    const [moduleId, setModuleId] = useState('')
    const [order, setOrder] = useState('asc');
    const [selectedScreen, setSelectedScreen] = useState([]);
    const [selectedActions, setselectedActions] = useState([]);
    const [orderBy, setOrderBy] = useState('first_name');
    const [IsLoading, setIsLoading] = useState(false)
    const [actionModle, setActionModle] = useState(false)
    const [finalData, setFinalData] = useState([])

    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    useEffect(() => {

        if (roleData?.module?.length) {
            const transformedData = roleData?.module?.map(module => ({
                module_id: module?.id, // Assign the module ID
                screens: module?.screens.map(item => ({
                    screen_id: item?.id, // Map screen ID
                    // screen_action_ids: module.screen_actions.map(action => action.id) // Map action IDs within screens
                    screen_action_ids: item?.screen_actions?.map(action => action?.screen_action ? action?.screen_action : '') // Map action IDs within screens
                }))
            }));


            console.log("transformedData", transformedData);
            setFinalData(transformedData)
            setSelectedScreen(transformedData?.flatMap(module => module?.screens.map(screen => screen?.screen_id)))
            setselectedScreenAction(transformedData?.flatMap(module => module?.screens.flatMap(screen => screen?.screen_action_ids)))

        }
    }, [roleData])


    useEffect(() => {
        fetchModuleData()
    }, [])

    //fetch admin moudle list
    const fetchModuleData = async () => {
        setModuleloader(true)
        const res = await getAdminAllModule();
        if (res?.data) {
            const filteredModule = res?.data.sort((a, b) => a.name.localeCompare(b.name));
            setModuleData(filteredModule)
            setModuleloader(false)
        }
        else {
            setModuleData([])
            setModuleloader(false)
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    // handle module Selection
    const handleModuleSelection = (id) => {
        setModuleId(id)
        setScreenId('')
        fetchScreenData(id)
    }

    // handle screen selection
    const handleScreenSelection = (id) => {
        setScreenId(id)
        fetchScreenAction(id)
    }

    // fetch screen of selected module
    const fetchScreenData = async (id) => {
        const res = await getAssignScreenOfAdminModule(id);


        if (res?.data) {
            const sortedScreen = res?.data.sort((a, b) => a.screen.descriptive_name.localeCompare(b.screen.descriptive_name));
            setScreenData(sortedScreen)
        }
        else {
            setScreenData([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    // fetch screen action of selected screen
    const fetchScreenAction = async (id) => {
        const res = await getScreenAllAction(id);

        if (res?.results) {
            setScreenActions(res?.results)
        }
        else {
            setScreenActions([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    const [screens, setScreens] = useState([]);
    const [selectedScreenAction, setselectedScreenAction] = useState([])

    const addActionToScreen = async (e, screenId, actionId) => {
        debugger
        if (e.target.checked) {
            //for screen action
            if (!selectedScreenAction?.includes(actionId)) {
                setselectedScreenAction([...selectedScreenAction, actionId]);
            }

            //for screen
            if (!selectedScreen?.includes(actionId)) {
                setSelectedScreen([...selectedScreen, screenId]);
            }

            setFinalData(prevModules => {
                const moduleIndex = prevModules.findIndex(module => module.module_id === moduleId);

                debugger
                if (moduleIndex > -1) {
                    const module = prevModules[moduleIndex];
                    const screenIndex = module.screens.findIndex(screen => screen.screen_id === screenId);

                    if (screenIndex > -1) {
                        const screen = module.screens[screenIndex];
                        if (!screen.screen_action_ids.includes(actionId)) {
                            const updatedScreen = { ...screen, screen_action_ids: [...screen.screen_action_ids, actionId] };
                            const updatedScreens = updateScreensArray(module.screens, screenIndex, updatedScreen);
                            return updateModulesArray(prevModules, moduleIndex, { ...module, screens: updatedScreens });
                        }
                        return prevModules;
                    } else {
                        return updateModulesArray(prevModules, moduleIndex, { ...module, screens: [...module.screens, { screen_id: screenId, screen_action_ids: [actionId] }] });
                    }
                } else {
                    return [...prevModules, { module_id: moduleId, screens: [{ screen_id: screenId, screen_action_ids: [actionId] }] }];
                }
            });


        }

        else {
            //for screen action
            if (selectedScreenAction?.includes(actionId)) {
                let removeItem = selectedScreenAction.filter(item => item !== actionId)
                setselectedScreenAction(removeItem);
            }

            if (selectedScreen.includes(screenId)) {
                const module = finalData.find(module => module.module_id === moduleId);
                const screen = module.screens.find(_ => _.screen_id === screenId);
                if (screen.screen_action_ids.length === 1) {
                    setSelectedScreen(prev => prev.filter(item => item !== screenId));
                }
            }


            // setFinalData(prevModules => {
            //     const moduleIndex = prevModules.findIndex(module => module.module_id === moduleId);

            //     if (moduleIndex > -1) {
            //         const module = prevModules[moduleIndex];
            //         const screenIndex = module.screens.findIndex(screen => screen.screen_id === screenId);

            //         if (screenIndex > -1) {
            //             const screen = module.screens[screenIndex];
            //             const updatedActionIds = screen.screen_action_ids.filter(id => id !== actionId);

            //             if (updatedActionIds.length > 0) {
            //                 const updatedScreen = { ...screen, screen_action_ids: updatedActionIds };
            //                 const updatedScreens = updateScreensArray(module.screens, screenIndex, updatedScreen);
            //                 return updateModulesArray(prevModules, moduleIndex, { ...module, screens: updatedScreens });
            //             }
            //             else {
            //                 const updatedScreens = removeScreenFromArray(module.screens, screenIndex);
            //                 return updateModulesArray(prevModules, moduleIndex, { ...module, screens: updatedScreens });
            //             }
            //         }
            //     }
            //     return prevModules;
            // });


            // Update modules state
            setFinalData(prevModules => {
                const moduleIndex = prevModules.findIndex(module => module.module_id === moduleId);

                if (moduleIndex > -1) {
                    const module = prevModules[moduleIndex];
                    const screenIndex = module.screens.findIndex(screen => screen.screen_id === screenId);

                    if (screenIndex > -1) {
                        const screen = module.screens[screenIndex];
                        const updatedActionIds = screen.screen_action_ids.filter(id => id !== actionId);

                        if (updatedActionIds.length > 0) {
                            // If there are still actions left, update the screen
                            const updatedScreen = { ...screen, screen_action_ids: updatedActionIds };
                            const updatedScreens = updateScreensArray(module.screens, screenIndex, updatedScreen);
                            return updateModulesArray(prevModules, moduleIndex, { ...module, screens: updatedScreens });
                        } else {
                            // If no actions are left, remove the screen
                            const updatedScreens = removeScreenFromArray(module.screens, screenIndex);

                            // If no screens are left in the module, remove the module itself
                            if (updatedScreens.length === 0) {
                                return removeModuleFromArray(prevModules, moduleIndex);
                            } else {
                                return updateModulesArray(prevModules, moduleIndex, { ...module, screens: updatedScreens });
                            }
                        }
                    }
                }
                return prevModules;
            });


            const module = finalData.find(_ => _?.module_id === moduleId);
            const screen = module?.screens?.find(screen => screen.screen_id === screenId);

            const checkScreenCount = module?.screens?.length || 0; //assign screen count

            const checkScreenActionCount = screen?.screen_action_ids?.length || 0; // assign screen actions count

            let payload
            
            if (checkScreenActionCount === 1 && checkScreenCount === 1) {
                payload = {
                    role_id: activeScreenRoleID,
                    module_id: moduleId,
                    "screens": [
                        {
                            "screen_id": screenId,
                            "screen_action_ids": actionId
                        },
                    ]
                }
            }
            else if (checkScreenActionCount > 1) {
                payload = {
                    role_id: activeScreenRoleID,
                    "screens": [
                        {
                            "screen_action_ids": actionId
                        },
                    ]
                }
            }
            else if (checkScreenActionCount === 1) {
                payload = {
                    role_id: activeScreenRoleID,
                    "screens": [
                        {
                            "screen_id": screenId,
                            "screen_action_ids": actionId
                        },
                    ]
                }
            }
            // else {
            //     payload = {
            //         role_id: activeScreenRoleID,
            //         "screens": [
            //             {
            //                 "screen_action_ids": actionId
            //             },
            //         ]
            //     }
            // }


            console.log("checkScreenActionCount", checkScreenActionCount);
            setIsLoading(true)

            if (selectedScreenAction.includes(actionId)) {
                const res = await deleteScreenActionFromModule(payload);
                if (res.message === 'Module data delete successfully.') {
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.response?.data?.message || res.message
                    });
                    // fetchOrgRoleData()
                    fetchAdminRoleData()
                    setIsLoading(false)
                }
                else {
                    setIsLoading(false)
                    // fetchOrgRoleData()
                    fetchAdminRoleData()
                    setToastData({
                        open: true,
                        status: 'warning',
                        message: res?.response?.data?.message || res.message
                    });
                }
            }
        }
    };

    const removeScreenFromArray = (screensArray, index) => {
        return [...screensArray.slice(0, index), ...screensArray.slice(index + 1)];
    };

    const removeModuleFromArray = (modulesArray, index) => {
        return [...modulesArray.slice(0, index), ...modulesArray.slice(index + 1)];
    };

    const updateModulesArray = (modulesArray, index, updatedModule) => {
        return [...modulesArray.slice(0, index), updatedModule, ...modulesArray.slice(index + 1)];
    };

    const updateScreensArray = (screensArray, index, updatedScreen) => {
        return [...screensArray.slice(0, index), updatedScreen, ...screensArray.slice(index + 1)];
    };




    console.log("screens", screens, selectedScreenAction);


    //assign in bulk 
    const assignBulkScreenToModule = async () => {
        let payload = {
            role_id: activeScreenRoleID,
            final_data: finalData
        }

        setIsLoading(true)
        const res = await screenActionAssignToModule(payload);
        if (res.message === 'Module data created successfully.') {
            setToastData({
                open: true,
                status: 'success',
                message: res?.response?.data?.message || res.message
            });
            // fetchOrgRoleData()
            fetchAdminRoleData()
            setScreens([])
            setIsLoading(false)
            handleClose()
        }
        else {
            handleClose()
            setIsLoading(false)
            // fetchOrgRoleData()
            fetchAdminRoleData()
            setToastData({
                open: true,
                status: 'warning',
                message: res?.response?.data?.message || res.message
            });
        }
    }


    console.log('finalData', finalData);

    return (
        <>
            {IsLoading && <Loader />}

            <Stack spacing={1} sx={{ borderRadius: '10px', height: 'calc(100vh - 146px)' }} >
                <>
                    <Stack
                        flexDirection={'row'}
                        style={{ width: '100%', height: '100%', overflow: 'auto' }}
                        gap={4}
                        sx={{ padding: '20px' }}
                    >
                        <div style={{ padding: '10px', border: '1px solid #D1D9E0', borderRadius: '10px' }} >
                            <Stack>
                                <h6 style={{ margin: '0', marginBottom: '10px', fontWeight: 700, paddingLeft: '10px' }} > Modules </h6>
                            </Stack>

                            {moduleloader ?
                                <Stack alignItems={'center'} justifyContent={'center'} style={{ height: '100%', minWidth: '352px' }}>
                                    <CircularProgress />
                                </Stack>
                                :

                                moduleData?.length ?
                                    <ul className='screenlist '>
                                        {
                                            moduleData.map((row) => {
                                                const { name, id } = row;
                                                return (
                                                    <>
                                                        <li style={{ cursor: 'pointer' }}
                                                            className={`${moduleId === id ? 'active' : ''}`}
                                                            onClick={() => handleModuleSelection(id)}>
                                                            {name}

                                                            <Checkbox
                                                                disableRipple
                                                                checked={finalData?.some(_ => _.module_id === id)}
                                                            // onChange={(e) => addActionToScreen(e, screen?.id, '')}
                                                            />

                                                        </li >
                                                    </>
                                                );
                                            })
                                        }
                                    </ul>
                                    :
                                    <ul className='screenlist screenlist_action '>
                                        <li className='noactions' style={{ pointerEvents: 'none', height: '100%' }}>
                                            <h6> No Data </h6>
                                        </li>
                                    </ul>
                            }
                        </div>
                        {console.log("screenId", selectedScreen, selectedScreen?.some(item => item === screen?.id))}

                        {moduleId ?
                            <div style={{ padding: '10px', border: '1px solid #D1D9E0', borderRadius: '10px' }} >
                                <Stack>
                                    <h6 style={{ margin: '0', marginBottom: '10px', fontWeight: 700, paddingLeft: '10px' }} > Screeens </h6>
                                </Stack>

                                <ul className='screenlist'>
                                    {screenData?.map((row) => {
                                        const { id, screen } = row;
                                        return (
                                            <>
                                                <li style={{ cursor: 'pointer' }}
                                                    className={`${screenId === screen.id ? 'active' : ''}`}
                                                    onClick={() => handleScreenSelection(screen.id)}
                                                >
                                                    {screen.descriptive_name}
                                                    <Checkbox
                                                        disableRipple
                                                        checked={selectedScreen?.some(item => item === screen?.id)}
                                                    // onChange={(e) => addActionToScreen(e, screen?.id, '')}
                                                    />
                                                </li >
                                            </>
                                        );
                                    })}
                                </ul>
                            </div>
                            :
                            ''
                        }

                        {console.log("selectedScreenAction", selectedScreenAction)}
                        {screenId ?
                            <div style={{ padding: '10px', border: '1px solid #D1D9E0', borderRadius: '10px' }} >
                                <Stack spacing={1} style={{ height: '100%' }}>
                                    <Stack> <h6 style={{ margin: '0', marginBottom: '10px', fontWeight: 700, paddingLeft: '10px' }} > Set Actions </h6> </Stack>
                                    <Stack style={{ height: '100%', marginTop: '0' }}>
                                        <ul className='screenlist_action'>
                                            {screenAction.length ?
                                                screenAction.map((row) => {
                                                    const { action, id } = row;
                                                    return (
                                                        <>
                                                            <li align="left">
                                                                {action}
                                                                <Checkbox
                                                                    checked={[...selectedScreenAction].includes(row.id)}
                                                                    onChange={(e) => addActionToScreen(e, screenId, row.id)}
                                                                />
                                                            </li>
                                                        </>
                                                    );
                                                })
                                                :
                                                screenId
                                                    ?
                                                    <li className='noactions'> <h6> No Data Found </h6> </li>
                                                    :
                                                    <li className='noactions'> <h6> select screen to see its assign/unassigned action </h6> </li>
                                            }
                                        </ul>
                                    </Stack>
                                </Stack>
                            </div>
                            :
                            ''
                        }
                    </Stack>
                    <Stack
                        className='updatelicense_btn'
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        gap={1}
                        style={{
                            marginTop: '0',
                            width: "100%",
                            padding: '20px',
                            boxShadow: '0px -2px 16px 0px #0000001F'
                        }}
                    >
                        <button className='outline_btn' onClick={handleClose} >
                            Cancel
                        </button>

                        {console.log("screen disable",

                            finalData.some(module =>
                                module.screens.some(screen =>
                                    screen.screen_action_ids.length > 0 && screen.screen_action_ids.some(id => id)
                                )
                            )

                        )}

                        <button
                            className='blue_btn'
                            sx={{ width: "100%" }}
                            onClick={() => assignBulkScreenToModule()}
                            disabled={
                                !finalData.some(module =>
                                    module.screens.some(screen =>
                                        screen.screen_action_ids.length > 0 && screen.screen_action_ids.some(id => id)
                                    )
                                )}
                        >
                            Assign
                        </button>
                    </Stack>
                </>
            </Stack>



            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignModalScreenAction