import axios from 'axios';

export const getAllMatters = async () => {
    try {
        const { data } = await axios.get(`matters`);
        return data;
    } catch (err) {
        return err;
    }
};


export const getEngagementService = async () => {
    try {
        const { data } = await axios.get(`engagement-service`);
        return data;
    } catch (err) {
        return err;
    }
};


export const getEngagementStatus = async () => {
    try {
        const { data } = await axios.get(`engagement-status`);
        return data;
    } catch (err) {
        return err;
    }
};

export const getEngagementFeeModule = async () => {
    try {
        const { data } = await axios.get(`engagement-fee-module`);
        return data;
    } catch (err) {
        return err;
    }
};

export const getRoleInMatter = async () => {
    try {
        const { data } = await axios.get(`role-in-matter`);
        return data;
    } catch (err) {
        return err;
    }
};


export const updateMatterData = async (matterId, payload) => {
    try {
        const { data } = await axios.put(`matters/${matterId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};



export const deleteMatterData = async (matterId) => {
    try {
        const { data } = await axios.delete(`matters/${matterId}`);
        return data;
    } catch (err) {
        return err;
    }
};


// http://159.89.161.222:8001/

// const instance = axios.create({
//     baseURL: 'http://159.89.161.222:8001/',
//     headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${localStorage.getItem('token')}`
//     }
// });


// engagement-latter-upload
export const engagementLatterUpload = async (payload) => {
    try {
        const { data } = await axios.post(`engagement-latter-upload`, payload);
        return data;
    } catch (err) {
        return err;
    }
}