import React, { useState } from 'react'
import {
    Card,
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Avatar,
} from '@mui/material';
import Toast from 'components/Toast';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignScreenToModule, deleteScreenActionFromModule, getAllScreen, getAssignScreenOfModule, getScreenAllAction, screenActionAssignToModule, unAssignScreenFromModule, updateScreenActionToModule } from 'services/UserAccessMasterService';
import defultPlholder from 'assets/images/defaultImg.png';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import AssignScreenActionModal from './AssignScreenActionModal';
import NewModalComponent from 'components/NewModalComponent';
import { screen } from '@testing-library/react';
import Loader from 'ui-component/Loader';





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'descriptive_name', label: 'Screen Name', alignRight: false },
    { id: '', label: 'Assign/UnAssign', alignRight: true },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) =>
            _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


function AssignScreenModal({ moduleId, handleClose, open }) {

    const [screenData, SetScreenData] = useState([])
    const [assignedScreen, setAssignedScreen] = useState([])
    const [screenId, setScreenId] = useState('')
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [selectedActions, setselectedActions] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [IsLoading, setIsLoading] = useState(false)
    const [actionModle, setActionModle] = useState(false)


    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    useEffect(() => {
        fetchAssignScreenOfmodule()
        fetchScreenData()
    }, [])


    const fetchScreenData = async () => {
        const res = await getAllScreen();
        if (res?.data) {
            SetScreenData(res?.data)
        }
        else {
            SetScreenData([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    const fetchAssignScreenOfmodule = async () => {
        const res = await getAssignScreenOfModule(moduleId);

        if (res?.data) {
            debugger
            setAssignedScreen(res?.data)
            let screenIds = res?.data.map(item => item.screen.id);
            setSelected(screenIds);

            let screenActionIds = res?.data.flatMap(item => item.screen_actions.map(action => action.screen_action));
            setselectedActions(screenActionIds)
        }
        else {
            setAssignedScreen([])
        }
    }


    //on check unchecked assign plan to user function
    const handleClick = async (event, screenId) => {

        if (event.target.checked === true) {
            const payload = {
                status: "ACTIVATE"
            };
            const res = await assignScreenToModule(screenId, moduleId, payload);

            if (res?.response?.status === 400) {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
            }
            else {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignScreenOfmodule()
            }
        }
        else {
            let ScreenID = assignedScreen?.filter((_) => _.screen.id === screenId)[0].id

            const res = await unAssignScreenFromModule(ScreenID);


            if (res.message === 'Screen Module mapping deleted successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignScreenOfmodule()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res.message
                });
            }
        }
    };


    const filteredScreen = applySortFilter(screenData, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen.length === 0;



    ///Screen Action Functions 

    const handleViewScreenAction = async (id) => {
        setActionModle(true)
        setScreenId(id)
    }


    const handleActionModulClose = () => {
        setActionModle(false)
    }

    console.log("selected", selected);

    const [screens, setScreens] = useState([]);
    const [selectedScreenAction, setselectedScreenAction] = useState([])

    const addActionToScreen = async (e, screenId, actionId) => {
        if (e.target.checked) {
            //for screen action
            if (!selectedScreenAction?.includes(actionId)) {
                setselectedScreenAction([...selectedScreenAction, actionId]);
            }

            //for screen
            if (!selected?.includes(actionId)) {
                setSelected([...selected, screenId]);
            }

            setScreens(prevScreens => {
                const screenIndex = prevScreens.findIndex(screen => screen.screen_id === screenId);
                if (screenIndex > -1) {
                    // Screen already exists
                    const screen = prevScreens[screenIndex];
                    debugger
                    if (!screen?.screen_action_ids?.includes(actionId)) {
                        // Add new action ID
                        const updatedScreen = { ...screen, screen_action_ids: [...screen.screen_action_ids, actionId] };
                        return [...prevScreens.slice(0, screenIndex), updatedScreen, ...prevScreens.slice(screenIndex + 1)];
                    }
                    return prevScreens;
                } else {
                    // Create new screen with action ID
                    debugger
                    if (actionId) {
                        // updatedScreen = { ...screen, screen_action_ids: [...screen.screen_action_ids, actionId] };
                        return [...prevScreens, { screen_id: screenId, screen_action_ids: [actionId] }];
                    }
                    else {
                        // updatedScreen = { ...screen, screen_action_ids: [] };
                        return [...prevScreens, { screen_id: screenId, screen_action_ids: [] }];
                    }

                    // return [...prevScreens, { screen_id: screenId, screen_action_ids: [actionId] }];
                }
            });
        }

        else {

            //for screen action
            if (selectedScreenAction?.includes(actionId)) {
                let removeItem = selectedScreenAction.filter(item => item !== actionId)
                setselectedScreenAction(removeItem);
            }

            //for screen
            if (selected?.includes(screenId)) {
                let checkScreenAction = screens.filter((_) => _.screen_id === screenId)[0]?.screen_action_ids?.length
                if (checkScreenAction === 1) {
                    let removeItem = selected.filter(item => item !== screenId)
                    setSelected(removeItem);
                }
            }


            setScreens((prevScreens) => {
                const screenIndex = prevScreens.findIndex((screen) => screen.screen_id === screenId);

                if (screenIndex > -1) {
                    const screen = prevScreens[screenIndex];
                    const updatedActionIds = screen.screen_action_ids.filter((id) => id !== actionId);

                    if (updatedActionIds.length > 0) {
                        // Update screen with remaining action IDs
                        const updatedScreen = { ...screen, screen_action_ids: updatedActionIds };
                        return [
                            ...prevScreens.slice(0, screenIndex),
                            updatedScreen,
                            ...prevScreens.slice(screenIndex + 1),
                        ];
                    } else {
                        // Remove screen if no action IDs left
                        return [
                            ...prevScreens.slice(0, screenIndex),
                            ...prevScreens.slice(screenIndex + 1),
                        ];
                    }
                }
                return prevScreens;
            });



            let payload
            debugger
            let checkScreenActionCount = assignedScreen.filter((_) => _?.screen?.id === screenId)[0].screen_actions?.length

            debugger
            if (checkScreenActionCount === 1) {
                payload = {
                    module_id: moduleId,
                    "screens": [
                        {
                            "screen_id": screenId,
                            "screen_action_ids": actionId
                        },
                    ]
                }
            }
            else {
                payload = {
                    module_id: moduleId,
                    "screens": [
                        {
                            "screen_action_ids": actionId
                        },
                    ]
                }
            }

            console.log("checkScreenActionCount", checkScreenActionCount);
            setIsLoading(true)

            if (selectedActions.includes(actionId)) {

                const res = await deleteScreenActionFromModule(payload);

                if (res.message === 'Module data delete successfully.') {
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.response?.data?.message || res.message
                    });
                    fetchAssignScreenOfmodule()
                    setIsLoading(false)
                }
                else {
                    setIsLoading(false)
                    setToastData({
                        open: true,
                        status: 'warning',
                        message: res?.response?.data?.message || res.message
                    });
                }
            }
        }
    };


    console.log("screens", screens, selectedScreenAction);

    const assignBulkScreenToModule = async () => {
        let payload = {
            module_id: moduleId,
            screens: [...screens]
        }
        debugger
        setIsLoading(true)
        const res = await screenActionAssignToModule(payload);
        if (res.message === 'Module data created successfully.') {
            setToastData({
                open: true,
                status: 'success',
                message: res?.response?.data?.message || res.message
            });
            fetchAssignScreenOfmodule()
            setScreens([])
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setToastData({
                open: true,
                status: 'warning',
                message: res?.response?.data?.message || res.message
            });
        }
    }



    return (
        <>
            {IsLoading && <Loader />}

            <Stack spacing={1}
                sx={{
                    border: '1px solid #D1D9E0',
                    borderRadius: '10px',
                    padding: '20px',
                    marginTop: '-18px',
                    height: 'calc(100vh - 110px)',
                    overflow: 'auto'
                }}
            >
                <>
                    <Stack mt={1}>
                        <h4>Assign Screens</h4>
                    </Stack>

                    <Stack flexDirection={'row'} style={{ width: '100%', height: 'calc(100% - 96px)' }} gap={3}>
                        <div >
                            <Stack mt={1}>
                                <h6>Screeens</h6>
                            </Stack>
                            <ul className='screenlist'>
                                {filteredScreen.map((row) => {
                                    const { descriptive_name, id, status } = row;
                                    const isItemSelected = selected?.indexOf(id) !== -1;
                                    return (
                                        <>
                                            <li style={{ cursor: 'pointer' }}
                                                className={`${screenId === id ? 'active' : ''}`}
                                                onClick={() => setScreenId(id)}>
                                                {descriptive_name}
                                                <Checkbox
                                                    disableRipple
                                                    checked={selected.some(item => item === row.id)}
                                                />
                                            </li >
                                        </>
                                    );
                                })}
                            </ul>
                        </div>

                        <div>
                            <AssignScreenActionModal
                                selectedScreenAction={[...selectedScreenAction, ...selectedActions]}
                                addActionToScreen={addActionToScreen}
                                moduleID={moduleId}
                                id={screenId}
                                onBack={() => handleActionModulClose()}
                            />
                        </div>
                    </Stack>
                    <Stack
                        className='updatelicense_btn'
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        gap={1} style={{ width: "100%" }}>
                        <button className='outline_btn'
                            onClick={handleClose}
                        >
                            Cancel
                        </button>

                        <button
                            className='blue_btn'
                            sx={{ width: "100%" }}
                            onClick={() => assignBulkScreenToModule()}
                            disabled={screens.every(screen => screen?.screen_action_ids?.length === 0)}
                        >
                            Assign
                        </button>
                    </Stack>
                </>
            </Stack>



            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignScreenModal