import React, { useState } from 'react'
import {
    Card,
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Avatar,
    FormControlLabel,
    Switch,
} from '@mui/material';
import Toast from 'components/Toast';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignScreenActionToModule, assignScreenToModule, getAllScreen, getAssignScreenActionToModule, getAssignScreenOfModule, getScreenAllAction, unAssignScreenActionFromModule, unAssignScreenFromModule, updateScreenActionToModule } from 'services/UserAccessMasterService';
import defultPlholder from 'assets/images/defaultImg.png';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));


const ACTION_TABLE_HEAD = [
    { id: 'action', label: 'Action', alignRight: false },
    { id: '', label: 'Status', alignRight: false },
    { id: '', label: 'Assign/UnAssign', alignRight: true },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) =>
            _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


function AssignScreenActionModal({ id, selectedScreenAction, moduleID, addActionToScreen }) {

    const [screenAction, SetScreenActions] = useState([])
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('action');
    const [filterName, setFilterName] = React.useState('');
    const [assignedScreenAction, setAssignedScreenAction] = useState([])

    const [actionModle, setActionModle] = useState(false)

    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        if (id) {
            // fetchAssignScreenScreenOfmodule()
            fetchScreenAction()
        }
    }, [id])

    const fetchScreenAction = async () => {
        debugger
        const res = await getScreenAllAction(id);

        if (res?.results) {
            SetScreenActions(res?.results)
        }
        else {
            SetScreenActions([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }


    // const fetchAssignScreenScreenOfmodule = async () => {
    //     const res = await getAssignScreenActionToModule(moduleID);

    //     if (res?.results) {
    //         setAssignedScreenAction(res?.results)
    //         let screenActionIds = res?.results.map(item => item.screen_action);
    //         debugger
    //         setSelected(screenActionIds, ...selectedScreenAction);
    //     }
    //     else {
    //         setAssignedScreenAction([])
    //     }
    // }


    //on check unchecked assign plan to user function
    const handleClick = async (event, screenActionId) => {

        if (event.target.checked === true) {
            const payload = {
                screen_action_id: screenActionId,
                module_id: moduleID,
                status: "ACTIVATE"
            };
            const res = await assignScreenActionToModule(payload);

            if (res.message === 'Screen action module mapping created successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                // fetchAssignScreenScreenOfmodule()
            }
            else {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
            }
        }
        else {

            let RemoveScreenActionID = assignedScreenAction?.filter((_) => _.screen_action === screenActionId)[0].id

            const res = await unAssignScreenActionFromModule(RemoveScreenActionID);


            if (res.message === 'Screen action module mapping deleted successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                // fetchAssignScreenScreenOfmodule()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res.message
                });
            }
        }
    };


    const filteredScreen = applySortFilter(screenAction, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen.length === 0;


    //handle table toggle 
    const toggleActive = async (e, id) => {

        let actionId = assignedScreenAction?.filter((_) => _.screen_action === id)[0]?.id

        if (actionId) {

            const tempScreenData = [...assignedScreenAction];
            tempScreenData.forEach(async (el) => {
                console.log("ël", el);
                if (el.screen_action === id) {
                    if (e.target.checked) {
                        el.status = 'ACTIVE';
                    } else {
                        el.status = 'INACTIVE';
                    }
                    const payload = {
                        screen_action_id: el?.screen_action,
                        module_id: el?.module,
                        status: el?.status,
                    };
                    const res = await updateScreenActionToModule(actionId, payload);

                    console.log(res, 'resData');
                    if (res.message === "Screen action module mapping updated successfully.") {
                        setToastData({
                            open: true,
                            status: 'success',
                            message: res?.message || 'Something went wrong'
                        });
                        // fetchAssignScreenScreenOfmodule();
                    }
                    else {
                        // fetchAssignScreenScreenOfmodule();
                        setToastData({
                            open: true,
                            status: 'error',
                            message: res?.response?.data?.message || 'Something went wrong'
                        });
                    }
                }
            });
        }
        else {
            setToastData({
                open: true,
                status: 'warning',
                message: 'Please First Assign Action Module To Update Its Status'
            });
        }
    };

    console.log("selectedScreenAction", selectedScreenAction)

    return (
        <>
            <Stack spacing={1} style={{ height: '100%' }}>
                <Stack mt={1}>
                    <h6>Set Action</h6>
                </Stack>

                <Card style={{ height: '100%' }}>
                    <ul className='screenlist_action'>
                        {screenAction.length ?
                            screenAction.map((row) => {
                                const { action } = row;
                                const isItemSelected = selectedScreenAction.indexOf(id) !== -1;
                                return (
                                    <>

                                        <li align="left">
                                            {action} <Checkbox
                                                checked={selectedScreenAction.includes(row.id)}
                                                onChange={(e) => addActionToScreen(e, id, row.id)}
                                            />
                                        </li>
                                        {/* <TableCell align="left">
                                            <FormControlLabel
                                                control={
                                                    <Switch onChange={(e) => toggleActive(e, id)}
                                                        checked={assignedScreenAction?.filter((_) => _.screen_action === id)[0]?.status === 'ACTIVE' ? true : false}
                                                    />
                                                }
                                                label=""
                                            />
                                        </TableCell> */}
                                    </>

                                );
                            })
                            :
                            <li className='noactions'>
                                <h6> select screen to see its assign/unassigned action </h6>
                            </li>
                        }
                    </ul>

                </Card>

            </Stack>

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignScreenActionModal