import { Alert, Snackbar } from '@mui/material';
import Fade from '@mui/material/Fade';
import React from 'react';

function Toast({ open, handleClose, status, message }) {
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                TransitionComponent={Fade}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}
export default Toast;
