import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_USER_ACCESS_API_URL,
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        if (token) {
            // Set the token in the request headers
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// Assign Role to plan
export const assignAdminRoleTplan = async (payload) => {
    try {
        const { data } = await instance.post(`admin-plan-role`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule delete
export const removeAssignAdminRoleTplan = async (moduleID) => {
    try {
        const { data } = await instance.delete(`admin-plan-role/${moduleID}`);
        return data;
    } catch (err) {
        return err;
    }
};


// get assign module to role 
export const getAssignAdminRoleToPlan = async (roleId) => {
    try {
        const { data } = await instance.get(`admin-plan-role/${roleId}`);
        return data;
    } catch (err) {
        return err;
    }
};





// /organization-role CRUD
// RoleModule get
export const getSuperAdminRole = async () => {
    try {
        const { data } = await instance.get(`admin-organization-role`);
        return data;
    } catch (err) {
        return err;
    }
};

//organization-role CRUD
// RoleModule get
export const getSingleAdminRole = async (id) => {
    try {
        const { data } = await instance.get(`admin-organization-role/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule create
export const createAdminRole = async (payload) => {
    try {
        const { data } = await instance.post(`admin-organization-role`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule update
export const updateAdminRole = async (orgRoleID, payload) => {
    try {
        const { data } = await instance.put(`admin-organization-role/${orgRoleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule delete
export const deleteAdminRole = async (orgRoleID) => {
    try {
        const { data } = await instance.delete(`admin-organization-role/${orgRoleID}`);
        return data;
    } catch (err) {
        return err;
    }
};



// get assign module to role 
export const getSingleAdminRoleModuleData = async (roleId) => {
    try {
        const { data } = await instance.get(`admin-role-module/${roleId}`);
        return data;
    } catch (err) {
        return err;
    }
};



// assign screen to module create
export const assignAdminModuleToAdminRole = async (payload) => {
    try {
        const { data } = await instance.post(`admin-role-module`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule delete
export const deleteRoleMappingToAdminModule = async (moduleID) => {
    try {
        const { data } = await instance.delete(`admin-role-module/${moduleID}`);
        return data;
    } catch (err) {
        return err;
    }
};






//================Super Admin Module Master ========================

//Module CRUD
// screen get
export const getAdminAllModule = async () => {
    try {
        const { data } = await instance.get(`admin-module`);
        return data;
    } catch (err) {
        return err;
    }
};

// Module create
export const createAdminModule = async (payload) => {
    try {
        const { data } = await instance.post(`admin-module`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// Module update
export const updateAdminModule = async (moduleID, payload) => {
    try {
        const { data } = await instance.put(`admin-module/${moduleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


// Module delete
export const deleteAdminModule = async (moduleID) => {
    try {
        const { data } = await instance.delete(`admin-module/${moduleID}`);
        return data;
    } catch (err) {
        return err;
    }
};





// ==================================================================================
//Assign Screen to Admin Module CRUD
// get assign screen of module
export const getAssignScreenOfAdminModule = async (moduleID) => {
    try {
        const { data } = await instance.get(`admin-screen-module/${moduleID}`);
        return data;
    } catch (err) {
        return err;
    }
};


// assign screen to module create
export const assignScreenToAdminModule = async (payload) => {
    try {
        const { data } = await instance.post(`admin-screen-module`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


// ScreenModule delete
export const unAssignScreenFromAdminModule = async (assignScreenID) => {
    try {
        const { data } = await instance.delete(`admin-screen-module/${assignScreenID}`);
        return data;
    } catch (err) {
        return err;
    }
};

// get assign screen to module 
// export const getAssignScreenActionToAdminModule = async (id) => {
//     try {
//         const { data } = await instance.get(`screen-action-module-mapping/${id}`);
//         return data;
//     } catch (err) {
//         return err;
//     }
// };


// // assign screen to module create
// export const assignScreenActionToAdminModule = async (payload) => {
//     try {
//         const { data } = await instance.post(`screen-action-module-mapping/`, payload);
//         return data;
//     } catch (err) {
//         return err;
//     }
// };


// // ScreenModule delete
// export const unAssignScreenActionFromAdminModule = async (assignScreenID) => {
//     try {
//         const { data } = await instance.delete(`screen-action-module-mapping/${assignScreenID}`);
//         return data;
//     } catch (err) {
//         return err;
//     }
// };

// update screen to module create
// export const updateScreenActionToAdminModule = async (id, payload) => {
//     try {
//         const { data } = await instance.put(`screen-action-module-mapping/${id}`, payload);
//         return data;
//     } catch (err) {
//         return err;
//     }
// };
