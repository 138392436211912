import React, { useState } from 'react'
import {
    Card,
    Table,
    Stack,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Toast from 'components/Toast';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignOrgDepartmentToRole, getAllOrgRoleModule, getAssignRoleToDept, unAssignOrgDepartmentToRole } from 'services/UserAccessMasterService';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { useAccount } from 'context/AccountProvider';
import NewModalComponent from 'components/NewModalComponent';
import UserModalToolbar from 'views/NewMatter/UserModalToolbar';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'Module Name', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}

function AssignDepartmentToRole({ id, handleClose, open }) {
    const { userDetails } = useAccount()
    const [orgDesignationData, SetOrgDesignationData] = useState([])
    const [assignedModule, setAssignedModule] = useState([])
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('first_name');
    const [filterName, setFilterName] = useState('');
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    useEffect(() => {
        fetchAssignOrgRole()
        fetchOrgRole()
    }, [])

    // fetch role list
    const fetchOrgRole = async () => {
        const res = await getAllOrgRoleModule();

        if (res?.data) {
            SetOrgDesignationData(res?.data)
        }
        else {
            SetOrgDesignationData([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    //fetch alredy assign data
    const fetchAssignOrgRole = async () => {
        const res = await getAssignRoleToDept(userDetails?.organisation?.id, id);

        if (res?.data) {
            setAssignedModule(res?.data)
            debugger
            let selectedId = res?.data?.map(item => item?.role);
            setSelected(selectedId);
        }
        else {
            setAssignedModule([])
        }
    }

    //on check unchecked assign plan to user function
    const handleClick = async (event, orgRoleID) => {
        if (event.target.checked === true) {
            const payload = {
                department: id,
                role: orgRoleID,
                status: "ACTIVATE"
            };
            const res = await assignOrgDepartmentToRole(userDetails.organisation.id, payload);
            if (res?.response?.status === 400) {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
            } else {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignOrgRole()
            }
        }
        else {
            let RemoveModuleId = assignedModule?.filter((_) => _.role === orgRoleID)[0].id
            const res = await unAssignOrgDepartmentToRole(userDetails?.organisation?.id, RemoveModuleId);
            if (res.message === 'Data deleted successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignOrgRole()
            } else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res.message
                });
            }
        }
    };

    const filteredScreen = applySortFilter(orgDesignationData, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen.length === 0;

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    return (
        <>
            <NewModalComponent
                width="100%"
                maxWidth="350px"
                open={open}
                handleClose={handleClose}
            >
                <Card>
                    <Stack px={3} pt={3} spacing={1}>
                        <Stack spacing={1}>
                            <h4> Roles</h4>
                        </Stack>

                        <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} />
                        <TableContainer sx={{ height: "400px" }}>
                            <Table>
                                {/* <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={orgDesignationData.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                /> */}
                                <TableBody>
                                    {filteredScreen.map((row) => {
                                        const { name, id } = row;
                                        const isItemSelected = selected.indexOf(id) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                            >
                                                <TableCell padding="checkbox" sx={{ border: 'none', paddingLeft: '0 !important', fontSize: '16px' }}>
                                                    <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                                </TableCell>
                                                <TableCell align="left" sx={{ border: 'none', padding: '0 !important', fontSize: '16px' }}>{name}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Stack>
                    <Stack
                        className='updatelicense_btn'
                        flexDirection={'row'} justifyContent={'flex-end'} gap={1} style={{ padding: "10px 20px", paddingTop: "0" }}>
                        <button className='outline_btn' onClick={handleClose}>
                            Cancel
                        </button>
                    </Stack>
                </Card>
            </NewModalComponent >
            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignDepartmentToRole