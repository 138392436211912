import React, { useState, useEffect } from 'react'
import {
    Card,
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Avatar,
} from '@mui/material';
import Toast from 'components/Toast';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignRoleToUser, getAssignedUserToRole, unAssignRoleToUser } from 'services/UserAccessMasterService';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { getUsersOfOrganisations } from 'services/OrganizationService';
import { useAccount } from 'context/AccountProvider';
import { assignUserToDataRole, getAssignUserToDataRole, unAssigUserToDataRole } from 'services/DataRoleService';
import NewModalComponent from 'components/NewModalComponent';
import UserModalToolbar from 'views/NewMatter/UserModalToolbar';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'User Name', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _?.first_name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}


function AssignDataRoleToUserPopup({ open, handleClose, activeDataRoleId }) {
    const { userDetails } = useAccount();
    const [allUsers, setAllUsers] = useState([]);
    const [assignedUser, SetAssignedUser] = useState([])
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    useEffect(() => {
        fetchAssignedUserToRole()
        getAllUsersHandler()
    }, [userDetails?.organisation?.id])


    //get all userlist of organisation
    const getAllUsersHandler = async () => {
        const res = await getUsersOfOrganisations(userDetails?.organisation?.id);

        if (res && res.length > 0) {
            setAllUsers(res.filter((_) => _.is_active === true))
        }
        else {
            setAllUsers([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }

    };


    const fetchAssignedUserToRole = async () => {
        const res = await getAssignUserToDataRole(userDetails?.organisation?.id, activeDataRoleId);

        if (res?.data) {
            SetAssignedUser(res?.data)
            let userIds = res?.data.map(item => item.user);
            setSelected(userIds);
        }
        else {
            SetAssignedUser([])
        }
    }


    //on check unchecked assign plan to user function
    const handleClick = async (event, userId) => {

        if (event.target.checked === true) {
            const payload = {
                user: userId,
                role: activeDataRoleId
            };
            const res = await assignUserToDataRole(userDetails?.organisation?.id, payload);

            if (res?.response?.status === 400) {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
            }
            else {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignedUserToRole()
            }
        }
        else {
            let RemoveUserId = assignedUser?.filter((_) => _.user === userId)[0].id
            const res = await unAssigUserToDataRole(userDetails?.organisation?.id, RemoveUserId);
            if (res.message === "Data deleted successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignedUserToRole()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res.message
                });
            }
        }
    };


    const filteredScreen = applySortFilter(allUsers, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen.length === 0;

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    return (
        <>
            <NewModalComponent
                width="100%"
                maxWidth="500px"
                open={open}
                handleClose={handleClose}
            >


                <Stack p={3} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Stack mt={1} sx={{ width: '100%' }} alignContent={'flex-start'}>
                        <h4> Assign User </h4>
                    </Stack>
                    <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} fullwidth />
                    <TableContainer sx={{ height: "400px" }}>
                        <Table>
                            {/* <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={allUsers.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                            /> */}
                            <TableBody>
                                {filteredScreen.map((row) => {
                                    const { first_name, last_name, user_type, email, id } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;
                                    return (
                                        <TableRow
                                            key={id}
                                            tabIndex={-1}
                                            sx={{ border: '0' }}
                                        >
                                            <TableCell sx={{ border: '0' }} padding="checkbox">
                                                <Checkbox sx={{ padding: '0' }} checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                            </TableCell>
                                            <TableCell sx={{ border: '0', padding: '10px 0 !important' }} align="left">{first_name} {last_name}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>

                    <Stack
                        className='updatelicense_btn'
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        gap={1} style={{ width: "100%" }}>
                        <button
                            style={{ width: '100%' }}
                            className='outline_btn'
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </Stack>

                </Stack>
            </NewModalComponent >
            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignDataRoleToUserPopup