import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import Loader from 'ui-component/Loader';
// import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegisterSign = Loadable(lazy(() => import('../views/pages/authentication/authentication/RegisterSign')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const ContractLib = Loadable(lazy(() => import('views/pages/authentication/contractLibrary/contractLibrary')));
const Library = Loadable(lazy(() => import('views/pages/authentication/Library/library')));
const ChangePassword = Loadable(lazy(() => import('views/pages/authentication/ChangePassword/changePassword')));
const ForgetPassword = Loadable(lazy(() => import('../views/pages/authentication/authentication/ForgetPassword')));
const ResetPassword = Loadable(lazy(() => import('../views/pages/authentication/authentication/ResetPassword')));
const PasswordSuccess = Loadable(lazy(() => import('../views/pages/authentication/authentication/PasswordSuccess')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

// const AuthenticationRoutes = {
//     path: '/',
//     element: <MinimalLayout />,
//     children: [
//         {
//             path: '/',
//             element: <ContractLib />
//         },
//         {
//             path: '/login',
//             element: <AuthLogin3 />
//         },
//         {
//             path: '/pages/register/register3',
//             element: <AuthRegister3 />
//         },
//         {
//             path: '/library',
//             element: <Library />
//         }
//     ]
// };
const token = localStorage.getItem('token');
const AuthenticationRoutes = [
    {
        path: '/',
        element: <ContractLib />
    },
    {
        path: '/login',
        element: <AuthLogin />
    },
    {
        path: '/register-signup',
        element: <AuthRegisterSign />
    },
    {
        path: '/register',
        element: <AuthRegister />
    },
    {
        path: '/library',
        element: <Library />
    },
    {
        path: '/ChangePassword',
        element: <ChangePassword />
    },
    {
        path: '/forgetPassword',
        element: <ForgetPassword />
    },
    {
        path: '/resetPassword',
        element: <ResetPassword />
    },
    {
        path: '/passwordSuccess',
        element: <PasswordSuccess />
    }
];

export default AuthenticationRoutes;
