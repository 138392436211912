import React, { useState, useEffect } from 'react'
import {
    Card,
    Table,
    Stack,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material';
import Toast from 'components/Toast';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignModuleToRole, deleteRoleModule, getAllOrgDepartment, getAllOrgDesignation } from 'services/UserAccessMasterService';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { useAccount } from 'context/AccountProvider';
import { assignDepartmentToRole, assignDesignationToRole, getDataRoleValue, updateDepartmentToData, updateDesignationToData } from 'services/DataRoleService';
import id from 'date-fns/locale/id';
import Loader from 'ui-component/Loader';
import NewModalComponent from 'components/NewModalComponent';
import UserModalToolbar from 'views/NewMatter/UserModalToolbar';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'Department Name', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}


function AssignDepartmentPopup({ open, handleClose, activeDataRoleId }) {
    const { userDetails } = useAccount();
    const [IsLoading, setIsLoading] = useState(false)
    const [departmentData, setDepartmentData] = useState([])
    const [assignedDepatment, setAssignedDepartment] = useState([])
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('first_name');
    const [filterName, setFilterName] = useState('');

    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    useEffect(() => {
        fetchAssignValueOfDataRole()
        fetchDepartmentData()
    }, [])

    const fetchAssignValueOfDataRole = async () => {
        const res = await getDataRoleValue(userDetails.organisation.id, activeDataRoleId);
        if (res.data) {
            setAssignedDepartment(res?.data[0])
            if (res?.data[0]?.data_role?.department?.length) {
                setSelected(res?.data[0]?.data_role?.department)
            }
            else {
                setSelected([])
            }
        }
        else { setAssignedDepartment([]) }
    }

    const fetchDepartmentData = async () => {
        const res = await getAllOrgDepartment();
        debugger
        if (res.results) {
            setDepartmentData(res.results)
        }
        else {
            setDepartmentData([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }


    //on check unchecked assign plan to user function
    const handleClick = async (event, departmentId) => {
        if (event.target.checked === true) {
            setIsLoading(true)
            const payload = {
                role: activeDataRoleId,
                data_role: {
                    department: departmentId
                },
                status: "ACTIVATE"
            };
            const res = await assignDepartmentToRole(userDetails.organisation.id, payload);

            if (res?.response?.status === 400) {
                setToastData({
                    open: true,
                    status: 'warning',
                    message: res?.response?.data?.message || res.message
                });
                setIsLoading(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignValueOfDataRole()
                setIsLoading(false)
            }
        }
        else {
            debugger
            setIsLoading(true)
            const filteredData = assignedDepatment?.data_role?.department?.filter(id => !(id === departmentId));
            const payload = {
                role: activeDataRoleId,
                data_role: {
                    department: filteredData
                },
                status: "ACTIVATE"
            };

            const res = await updateDepartmentToData(userDetails.organisation.id, assignedDepatment?.id, payload);
            if (res.message === "Data Update successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.response?.data?.message || res.message
                });
                fetchAssignValueOfDataRole()
                setIsLoading(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res.message
                });
                setIsLoading(false)
            }
        }
    };


    const filteredScreen = applySortFilter(departmentData, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen?.length === 0;

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    return (
        <>
            {IsLoading && <Loader />}
            <NewModalComponent
                width="100%"
                maxWidth="500px"
                open={open}
                handleClose={handleClose}
            >


                <Stack p={3} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Stack mt={1} sx={{ width: '100%' }} alignContent={'flex-start'}>
                        <h4> Assign Department </h4>
                    </Stack>

                    <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} fullwidth />

                    <TableContainer sx={{ height: "400px" }}>
                        <Table>
                            {/* <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={departmentData?.length}
                                numSelected={selected?.length}
                                onRequestSort={handleRequestSort}
                            /> */}
                            <TableBody>
                                {filteredScreen.map((row) => {
                                    const { name, id } = row;
                                    const isItemSelected = selected?.indexOf(id) !== -1;
                                    return (
                                        <TableRow
                                            key={id}
                                            tabIndex={-1}
                                            sx={{ border: '0' }}
                                        >
                                            <TableCell sx={{ border: '0' }} padding="checkbox">
                                                <Checkbox sx={{ padding: '0' }} checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                            </TableCell>
                                            <TableCell sx={{ border: '0', padding: '10px 0 !important' }} align="left">{name}</TableCell>
                                        </TableRow>

                                    );
                                })}
                            </TableBody>

                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <Stack
                        className='updatelicense_btn'
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        gap={1} style={{ width: "100%" }}>
                        <button
                            style={{ width: '100%' }}
                            className='outline_btn'
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </Stack>
                </Stack>
            </NewModalComponent>
            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignDepartmentPopup