import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, TableContainer, TablePagination, TextField, CardContent, Paper, FormControlLabel, Switch, MenuItem, ListItemIcon, IconButton, Typography, tableCellClasses, TableHead, CircularProgress } from '@mui/material';

import Scrollbar from 'components/Scrollbar';
import { UserListHead, UserListToolbar } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { styled } from '@mui/system';
import Toast from 'components/Toast';
import { updateEngagementStatus } from 'services/ClientService';
import Iconify from 'components/Iconify';
import { createScreen, createScreenAction, deleteAction, deleteScreen, getAllScreen, getScreenAllAction, updateScreen, updateScreenAction } from 'services/UserAccessMasterService';
import ScreenMoreMenu from './ScreenMoreMenu';
import StyledTableListHead from 'views/StyledTable/StyledTableListHead';
import { CustomIOSSwitch } from 'components/ISOSwitch';
import NewModalComponent from 'components/NewModalComponent';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));




const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td ': {
        borderRight: "1px solid #D1D9E0 !important"
    },
    'th ': {
        borderBottom: "1px solid #D1D9E0 !important"
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    '&:last-child': {
        borderRight: '0 !important',
    },
    fontSize: '14px',
    fontWeight: 500,
    color: '#404040',
    padding: '10px !important',

}));



const TABLE_HEAD = [
    { id: 'descriptive_name', label: 'Descriptive Name', alignRight: false },
    { id: 'slug_name', label: 'Slug Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Assign/View Actions', alignRight: false },
    { id: '', label: '', alignRight: true },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _?.descriptive_name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


function ScreenMaster() {

    const [screenData, SetScreenData] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    const [ListLoading, setListLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [secondLoader, setSecondLoader] = useState(false)
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [createModal, setCreateModal] = useState(false)
    const [actionModal, setActionModal] = useState(false)
    const [isCreateAction, setIsCreateAction] = useState(true)
    const [screenId, setScreenId] = useState('')
    const [screenAction, setScreenAction] = useState([])
    const [screenDataValue, setScreenDataValue] = useState({
        slug_name: '',
        descriptive_name: '',
        status: 'INACTIVE',
    });

    const [ActionValue, setActionValue] = useState({
        action: '',
        status: 'INACTIVE',
    });



    const [prevscreenData, setprevScreenData] = useState([])


    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        setListLoading(true)
        fetchScreenData()
    }, [])




    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - screenData?.length) : 0;

    const filterScreenData = applySortFilter(screenData, getComparator(order, orderBy), filterName);

    const isDataNotFound = filterScreenData?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setScreenDataValue((prev) => ({ ...prev, [name]: value }));
    };

    const handleActionChange = (e) => {
        const { name, value } = e.target;
        setActionValue((prev) => ({ ...prev, [name]: value }));
    };

    const handleSwitch = (name, value) => {
        let status
        if (value) {
            status = 'ACTIVE';
        } else {
            status = 'INACTIVE';
        }
        setScreenDataValue((prev) => ({ ...prev, [name]: status }));
    }

    const handleActionSwitch = (name, value) => {

        let status
        if (value) {
            status = 'ACTIVE';
        } else {
            status = 'INACTIVE';
        }
        setActionValue((prev) => ({ ...prev, [name]: status }));
    }


    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const fetchScreenData = async () => {
        const res = await getAllScreen();
        if (res?.data) {
            setListLoading(false)
            SetScreenData(res?.data)
        }
        else {
            setListLoading(false)
            SetScreenData([])
            setToastData({
                open: true,
                status: 'success',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    const EditScreenStatus = (id, data) => {
        setIsEdit(true)
        setScreenDataValue(data)
        setprevScreenData(data)
        setCreateModal(true)
    }



    const handelCloseModal = () => {

        setScreenDataValue({
            slug_name: '',
            descriptive_name: '',
            status: 'INACTIVE',
        })
        setActionModal(false)
        setCreateModal(false)
        setIsEdit(false)
        setIsCreateAction(true)

    }


    const findChangedData = (previousObj, currentObj) => {
        const changedData = {};
        for (const key in previousObj) {
            if (previousObj[key] !== currentObj[key]) {
                changedData[key] = currentObj[key];
            }
        }
        return changedData;
    };

    const handleCreateScreen = async (event) => {
        event.preventDefault()
        let payload = {
            ...screenDataValue,
            status: screenDataValue?.status
        }

        if (isEdit) {
            const newChangedKeys = findChangedData(prevscreenData, screenDataValue);

            const res = await updateScreen(screenDataValue?.id, newChangedKeys);
            if (res?.message === "Screen data updated successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchScreenData();
                setIsEdit(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || 'Something went wrong'
                });
            }
        }
        else {
            const res = await createScreen(payload);

            if (res.message === "Screen data created successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchScreenData();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }
        }
    }



    const toggleActive = async (e, id) => {
        const tempScreenData = [...screenData];
        tempScreenData.forEach(async (el) => {
            console.log("ël", el);
            if (el.id === id) {
                if (e.target.checked) {
                    el.status = 'ACTIVE';
                } else {
                    el.status = 'INACTIVE';
                }
                const payload = {
                    status: el.status,
                };
                setLoading(true)
                const res = await updateScreen(id, payload);
                console.log(res, 'resData');
                setLoading(false)
                if (res.message === "Screen data updated successfully.") {
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.message || 'Something went wrong'
                    });
                    fetchScreenData();
                }
                else {
                    setLoading(false)

                    fetchScreenData();
                    setToastData({
                        open: true,
                        status: 'error',
                        message: res?.response?.data?.message || 'Something went wrong'
                    });
                }
            }
        });

    };

    const removeScreenData = async (id) => {
        const res = await deleteScreen(id);
        if (res.message === "Screen deleted successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchScreenData();
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || res?.message || 'Something went wrong'
            });
        }
    }


    //function for action 
    const AddActionToScreen = (id, data) => {
        setActionModal(true)
        getActionOfScreen(id)
        setScreenId(id)
    }

    const getActionOfScreen = async (id) => {
        setSecondLoader(true)
        const res = await getScreenAllAction(id);

        if (res.results) {
            setScreenAction(res.results?.sort((a, b) => a.action.toLowerCase().localeCompare(b.action.toLowerCase())))
            setSecondLoader(false)
        }
        else {
            setSecondLoader(false)
            SetScreenData([])
            setToastData({
                open: true,
                status: 'success',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    const CreateActionForScreen = async (event) => {
        event.preventDefault()
        let payload = {
            ...ActionValue,
            screen_id: screenId,
            status: ActionValue?.status
        }
        const res = await createScreenAction(payload);

        if (res.message === "Screen action created successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });

            AddActionToScreen(screenId);
            setIsCreateAction(true)
            setActionValue({
                action: '',
                status: 'INACTIVE',
            })
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || res?.message || 'Something went wrong'
            });
        }
    }



    const getActionOfScreenAfterToggle = async (id) => {
        const res = await getScreenAllAction(id);

        if (res.results) {
            setScreenAction(res.results?.sort((a, b) => a.action.toLowerCase().localeCompare(b.action.toLowerCase())))
        }
        else {
            SetScreenData([])
            setToastData({
                open: true,
                status: 'success',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }

    const toggleActionActive = async (e, id) => {
        const tempscreenAction = [...screenAction];
        tempscreenAction.forEach(async (el) => {
            console.log("ël", el);
            if (el.id === id) {
                if (e.target.checked) {
                    el.status = 'ACTIVE';
                } else {
                    el.status = 'INACTIVE';
                }
                const payload = {
                    action: el.action,
                    screen_id: el.screen,
                    status: el.status,
                };
                setLoading(true)
                const res = await updateScreenAction(id, payload);
                console.log(res, 'resData');
                if (res.message === "Screen action updated successfully.") {
                    setLoading(false)
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.message || 'Something went wrong'
                    });
                    getActionOfScreenAfterToggle(screenId);
                }
                else {
                    setLoading(false)
                    getActionOfScreenAfterToggle(screenId);
                    setToastData({
                        open: true,
                        status: 'error',
                        message: res?.response?.data?.message || 'Something went wrong'
                    });
                }
            }
        });

    };


    const deleteScreenAction = async (id) => {
        const res = await deleteAction(id);

        if (res.message === "Screen action deleted successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            AddActionToScreen(screenId);
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || res?.message || 'Something went wrong'
            });
        }
    }

    return (
        <>
            {console.log(screenData)}
            {(IsLoading || loading) && <Loader />}

            <Card padding={5}>
                <Stack className='breadcrubs' mt={2} justifyItems={'flex-start'} alignItems={'flex-start'}>
                    <h5> Screen Master </h5>

                    <Stack mt={2} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'} sx={{ width: '100%' }}>
                        <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                        <button className='blue_btn' onClick={() => setCreateModal(!createModal)} >
                            Add Screen
                        </button>

                    </Stack>
                </Stack>

                <Scrollbar>
                    <TableContainer sx={{ minWidth: 400, marginTop: "20px" }}>
                        <Table
                            sx={{
                                background: 'white',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                border: '1px solid #D1D9E0',
                                borderCollapse: 'unset',
                                overflow: 'hidden'
                            }}
                        >
                            <StyledTableListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={screenData?.length}
                                onRequestSort={handleRequestSort}
                            />
                            {console.log("filterScreenData", filterScreenData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))}
                            <TableBody>
                                {ListLoading ?

                                    <StyledTableRow>
                                        <StyledTableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                            <CircularProgress />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    :
                                    <>
                                        {filterScreenData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                            const { id, descriptive_name, slug_name, status } = row;
                                            return (
                                                <StyledTableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                >
                                                    <StyledTableCell align="left"> {descriptive_name ? descriptive_name : '-'} </StyledTableCell>
                                                    <StyledTableCell align="left"> {slug_name ? slug_name : '-'} </StyledTableCell>
                                                    <StyledTableCell align="left" >
                                                        <CustomIOSSwitch
                                                            handleSwitch={(e) => toggleActive(e, id)}
                                                            status={status}
                                                        />
                                                    </StyledTableCell>

                                                    <StyledTableCell align="left" >
                                                        <Button
                                                            onClick={() => AddActionToScreen(id)}
                                                            color="primary"
                                                        >
                                                            Assign/View Actions
                                                        </Button>
                                                    </StyledTableCell>

                                                    <StyledTableCell align="right" width={'50px'}>
                                                        <ScreenMoreMenu
                                                            onEdit={EditScreenStatus}
                                                            id={id}
                                                            data={row}
                                                            removeData={removeScreenData}
                                                        // addAction={AddActionToScreen}
                                                        />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <StyledTableRow style={{ height: 53 * emptyRows }}>
                                                <StyledTableCell colSpan={13} />
                                            </StyledTableRow>
                                        )}
                                        {isDataNotFound && (

                                            <StyledTableRow>
                                                <StyledTableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </StyledTableCell>
                                            </StyledTableRow>

                                        )}
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={screenData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>


            <NewModalComponent
                width="100%"
                maxWidth="350px"
                open={createModal} handleClose={() => handelCloseModal()}>
                <Card>
                    <Stack px={3} pt={3} direction="column" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                        <Stack mt={1}>
                            <h4>Add Screen Data</h4>
                        </Stack>

                        <form onSubmit={handleCreateScreen}>
                            <TextField
                                fullWidth
                                size="small"
                                style={{ width: '300px', marginBottom: '16px' }}
                                type="text"
                                label="Descriptive Name"
                                variant="outlined"
                                value={screenDataValue?.descriptive_name}
                                name="descriptive_name"
                                onChange={handleChange}
                                required
                            />

                            <TextField
                                fullWidth
                                size="small"
                                style={{ width: '300px' }}
                                type="text"
                                label="Slug Name"
                                variant="outlined"
                                value={screenDataValue?.slug_name}
                                name="slug_name"
                                onChange={handleChange}
                                required
                            />

                            <Stack direction="row"
                                spacing={2}
                                mb={2}
                                mt={2}
                                sx={{
                                    border: ' 1px solid #D1D9E0',
                                    borderRadius: '10px',
                                    padding: '10px 14px'
                                }}
                                alignItems='center'
                                justifyContent={'space-between'}
                            >
                                <label style={{ color: '#606060', fontWeight: '400' }}> Status </label>

                                <CustomIOSSwitch
                                    handleSwitch={(e) => handleSwitch('status', e.target.checked)}
                                    status={screenDataValue?.status}
                                />
                            </Stack>

                            <Stack
                                className='updatelicense_btn'
                                flexDirection={'row'}
                                justifyContent={'flex-end'}
                                gap={1} style={{ padding: "14px 0", paddingTop: "0", width: "100%" }}>
                                <button className='outline_btn' onClick={handelCloseModal}>
                                    Cancel
                                </button>
                                <button className='blue_btn' type='submit' sx={{ width: "100%" }} >
                                    {isEdit ? "Update" : "Create"}
                                </button>
                            </Stack>

                        </form>
                    </Stack>
                </Card>
            </NewModalComponent >


            <NewModalComponent width="900" open={actionModal} handleClose={() => handelCloseModal()}>

                <Stack p={3} direction="column" spacing={1}>

                    {!isCreateAction ?
                        <>
                            <h5> Add Action </h5>
                            <form onSubmit={CreateActionForScreen}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    label="Action"
                                    variant="outlined"
                                    value={ActionValue?.action}
                                    name="action"
                                    onChange={handleActionChange}
                                    required
                                />
                                <Stack direction="row"
                                    spacing={2}
                                    mb={2}
                                    mt={2}
                                    sx={{
                                        border: ' 1px solid #D1D9E0',
                                        borderRadius: '10px',
                                        padding: '10px 14px'
                                    }}
                                    alignItems='center'
                                    justifyContent={'space-between'}
                                >
                                    <label style={{ color: '#606060', fontWeight: '400' }}> Status </label>

                                    <CustomIOSSwitch
                                        handleSwitch={(e) => handleActionSwitch('status', e.target.checked)}
                                        status={ActionValue?.status}
                                    />
                                </Stack>

                                <Stack
                                    className='updatelicense_btn'
                                    flexDirection={'row'}
                                    justifyContent={'flex-end'}
                                    gap={1} style={{ padding: "14px 0", paddingTop: "0", width: "100%" }}>
                                    <button className='outline_btn' onClick={() => setIsCreateAction(true)}>
                                        Back
                                    </button>
                                    <button className='blue_btn'
                                        sx={{ width: "100%" }}
                                        type='submit'
                                    >
                                        {isEdit ? "Update" : "Create"}
                                    </button>
                                </Stack>

                            </form>
                        </>

                        :

                        <>

                            <Stack direction="row" justifyContent="flex-start" mb={2}>
                                <Button
                                    variant="contained"
                                    component={RouterLink}
                                    to="#"
                                    onClick={() => setIsCreateAction(false)}
                                    startIcon={<Iconify icon="eva:plus-fill" />}
                                    sx={{ color: "#FFF !important " }} >
                                    Add Action
                                </Button>
                            </Stack>


                            {secondLoader ?
                                <Stack alignItems={'center'} justifyContent={'center'} style={{ height: '400px' }}>
                                    <CircularProgress />
                                </Stack>
                                :
                                <TableContainer sx={{ height: "400px" }}>
                                    <Table>
                                        <StyledTableListHead
                                            order={'asc'}
                                            orderBy={orderBy}
                                            headLabel={
                                                [
                                                    { id: 'name', label: 'Name', alignRight: false },
                                                    { id: 'status', label: 'Status', alignRight: false },
                                                    { id: '', label: '', alignRight: true },
                                                ]
                                            }
                                        />
                                        <TableBody>
                                            {screenAction.length > 0 ?
                                                screenAction?.map((row, i) => {
                                                    const { id, action, status } = row;
                                                    return (
                                                        <StyledTableRow
                                                            key={id}
                                                            tabIndex={-1}
                                                        >
                                                            <StyledTableCell align="left" style={{ padding: "10px" }}> {action ? action : '-'} </StyledTableCell>

                                                            <StyledTableCell align="left" style={{ padding: "10px" }} >
                                                                <CustomIOSSwitch
                                                                    handleSwitch={(e) => toggleActionActive(e, id)}
                                                                    status={status}
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center" style={{ padding: "10px" }}>
                                                                <IconButton aria-label="delete"
                                                                    onClick={() => deleteScreenAction(id)}
                                                                >
                                                                    <Iconify icon="eva:trash-2-outline" />
                                                                </IconButton>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    );
                                                })
                                                :

                                                <StyledTableRow>
                                                    <StyledTableCell align="center" colSpan={3} sx={{ py: 3 }}>
                                                        <Typography gutterBottom align="center" variant="subtitle1">
                                                            Not Action Found
                                                        </Typography>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }

                        </>
                    }
                </Stack>

            </NewModalComponent >

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />

        </>

    )
}

export default ScreenMaster