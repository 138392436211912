import axios from 'axios';

export const createClient = async (payload) => {
    try {
        const { data } = await axios.post(`clients`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

export const updateClient = async (clientId, payload) => {
    try {
        const { data } = await axios.put(`clients/${clientId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


export const getAllClient = async () => {
    try {
        const { data } = await axios.get(`clients`);
        return data;
    } catch (err) {
        return err;
    }
};

export const addMatterToClient = async (clientId, payload) => {
    try {
        const { data } = await axios.post(`clients/${clientId}/matters`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


export const getMatterByClient = async (clientId) => {
    try {
        const { data } = await axios.get(`clients/${clientId}/matters`);
        return data;
    } catch (err) {
        return err;
    }
};



// ===============================================================================


//industry API endpoint

// add industry
export const addIndustry = async (payload) => {
    try {
        const { data } = await axios.post(`industry`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//get industry
export const getIndustry = async () => {
    try {
        const { data } = await axios.get(`industry`);
        return data;
    } catch (err) {
        return err;
    }
};

//update industry
export const updateIndustry = async (id, payload) => {
    try {
        const { data } = await axios.put(`industry/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//delete industry 
export const deleteIndustry = async (id) => {
    try {
        const { data } = await axios.delete(`industry/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

// ===============================================================================

//ClientEntity API endpoint

// add client-entity
export const addClientEntity = async (payload) => {
    try {
        const { data } = await axios.post(`client-entity`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//get client-entity
export const getClientEntity = async () => {
    try {
        const { data } = await axios.get(`client-entity`);
        return data;
    } catch (err) {
        return err;
    }
};


//update client-entity
export const updateClientEntity = async (id, payload) => {
    try {
        const { data } = await axios.put(`client-entity/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//delete client-entity 
export const deleteClientEntity = async (id) => {
    try {
        const { data } = await axios.delete(`client-entity/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};


// ===============================================================================

//RoleInMatter API endpoint

// add role-in-matter
export const addRoleInMatter = async (payload) => {
    try {
        const { data } = await axios.post(`role-in-matter`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//get role-in-matter
export const getRoleInMatter = async () => {
    try {
        const { data } = await axios.get(`role-in-matter`);
        return data;
    } catch (err) {
        return err;
    }
};


//update role-in-matter
export const updateRoleInMatter = async (id, payload) => {
    try {
        const { data } = await axios.put(`role-in-matter/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//delete role-in-matter 
export const deleteRoleInMatter = async (id) => {
    try {
        const { data } = await axios.delete(`role-in-matter/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};





// ===============================================================================

//EngagementService API endpoint

// add engagement-service
export const addEngagementService = async (payload) => {
    try {
        const { data } = await axios.post(`engagement-service`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//get engagement-service
export const getEngagementService = async () => {
    try {
        const { data } = await axios.get(`engagement-service`);
        return data;
    } catch (err) {
        return err;
    }
};


//update engagement-service
export const updateEngagementService = async (id, payload) => {
    try {
        const { data } = await axios.put(`engagement-service/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//delete engagement-service 
export const deleteEngagementService = async (id) => {
    try {
        const { data } = await axios.delete(`engagement-service/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};



// ===============================================================================

//EngagementStatus API endpoint

//add engagement-Status
export const addEngagementStatus = async (payload) => {
    try {
        const { data } = await axios.post(`engagement-status`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//get engagement-Status
export const getEngagementStatus = async () => {
    try {
        const { data } = await axios.get(`engagement-status`);
        return data;
    } catch (err) {
        return err;
    }
};



//update engagement-status
export const updateEngagementStatus = async (id, payload) => {
    try {
        const { data } = await axios.put(`engagement-status/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};



//delete engagement-Status
export const deleteEngagementStatus = async (id) => {
    try {
        const { data } = await axios.delete(`engagement-status/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};