import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, TableContainer, TablePagination, TextField, CardContent, Paper, FormControlLabel, Switch, MenuItem, ListItemIcon, IconButton, Typography } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { styled } from '@mui/system';
import Toast from 'components/Toast';
import Iconify from 'components/Iconify';
import DataMoreMenu from './DataMoreMenu';
import { createDataItem, deleteDataItem, getDataList, updateDataItem } from 'services/DataRoleService';
import DataListToolbar from './DataListToolbar';
import { CommonListHead } from 'sections/@dashboard/common';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'label', label: 'Data Name', alignRight: false },
    { id: 'value', label: 'Data Key', alignRight: false },
    { id: 'action', label: 'Action', alignRight: true },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.label?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}



function Datamaster() {

    const [dataMasterData, SetDataMasterData] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [createModal, setCreateModal] = useState(false)
    const [actionModal, setActionModal] = useState(false)
    const [isCreateAction, setIsCreateAction] = useState(true)

    const [dataMasterValue, setDataMasterValue] = useState({
        label: '',
        value: '',
    });

    const [ActionValue, setActionValue] = useState({
        action: '',
        status: 'INACTIVE',
    });
    const [prevdataMasterData, setprevDataMasterData] = useState([])


    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        fetchDataMasterData()
    }, [])

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataMasterData?.length) : 0;

    const filterCoupon = applySortFilter(dataMasterData, getComparator(order, orderBy), filterName);

    const isDataNotFound = filterCoupon?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDataMasterValue((prev) => ({ ...prev, [name]: value }));
    };

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const fetchDataMasterData = async () => {
        const res = await getDataList();
        if (res?.data) {
            SetDataMasterData(res?.data)
        }
        else {
            SetDataMasterData([])
            setToastData({
                open: true,
                status: 'success',
                message: res.response.data.message || 'Something went wrong'
            });
        }
    }


    const EditDataStatus = (id, data) => {
        setIsEdit(true)
        setDataMasterValue(data)
        setprevDataMasterData(data)
        setCreateModal(true)
    }



    const handelCloseModal = () => {
        setDataMasterValue({
            label: '',
            value: '',
        })
        setActionModal(false)
        setCreateModal(false)
        setIsEdit(false)
        setIsCreateAction(true)

    }



    const findChangedData = (previousObj, currentObj) => {
        const changedData = {};
        for (const key in previousObj) {
            if (previousObj[key] !== currentObj[key]) {
                changedData[key] = currentObj[key];
            }
        }
        return changedData;
    };



    const handleCreateScreen = async (event) => {
        event.preventDefault()
        let payload = {
            ...dataMasterValue
        }

        if (isEdit) {
            const newChangedKeys = findChangedData(prevdataMasterData, dataMasterValue);
            const res = await updateDataItem(dataMasterValue?.id, newChangedKeys);
            if (res?.message === "Data updated successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchDataMasterData();
                setIsEdit(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || 'Something went wrong'
                });
            }
        }
        else {
            const res = await createDataItem(payload);
            debugger

            if (res.message === "Data created successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                handelCloseModal();
                fetchDataMasterData();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }
        }
    }

    const removeDataData = async (item) => {
        const res = await deleteDataItem(item.id);
        if (res.message === "Data deleted successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchDataMasterData();
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || res?.message || 'Something went wrong'
            });
        }
    }


    return (
        <>
            {IsLoading && <Loader />}
            <div className='country'>
                <Stack direction="row" justifyContent="flex-start" mb={2}>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="#"
                        onClick={() => setCreateModal(!createModal)}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        sx={{ color: "#FFF !important " }}
                    >
                        Add Data Item
                    </Button>
                </Stack>

                <Card padding={5}>
                    <DataListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                    <CardContent >
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 400 }}>
                                <Table>
                                    <CommonListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={dataMasterData?.length}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {filterCoupon?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                            const { id, value, label, status } = row;
                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell align="left"> {label ? label : '-'} </TableCell>
                                                    <TableCell align="left"> {value ? value : '-'} </TableCell>
                                                    <TableCell align="right">
                                                        <DataMoreMenu
                                                            onEdit={EditDataStatus}
                                                            id={id}
                                                            data={row}
                                                            removeData={removeDataData}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={13} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    {isDataNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </CardContent>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={dataMasterData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    {console.log("prevdataMasterData", prevdataMasterData)}
                </Card>
            </div >



            <ModalComponent width="900" open={createModal} handleClose={() => handelCloseModal()}>
                <Card>
                    <Stack px={2} pt={2} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                        <Stack mt={1}>
                            <h4>Add Data Info</h4>
                        </Stack>

                        <form onSubmit={handleCreateScreen}>
                            <Item>
                                <TextField
                                    size="small"
                                    style={{ width: '300px' }}
                                    type="text"
                                    label="Data Name"
                                    variant="outlined"
                                    value={dataMasterValue?.label}
                                    name="label"
                                    onChange={handleChange}
                                    required
                                />
                            </Item>
                            <Item>
                                <TextField
                                    size="small"
                                    style={{ width: '300px' }}
                                    type="text"
                                    label="Data Key"
                                    variant="outlined"
                                    value={dataMasterValue?.value}
                                    name="value"
                                    onChange={handleChange}
                                    required
                                />
                            </Item>
                            <Item>
                                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type='submit'
                                    >
                                        {isEdit ? "Update" : "Add"}
                                    </Button>
                                    <Button variant="contained" onClick={() => handelCloseModal()} color="primary">
                                        Back
                                    </Button>
                                </Stack>
                            </Item>
                        </form>
                    </Stack>
                </Card>
            </ModalComponent >

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default Datamaster