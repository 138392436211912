import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Grid, Typography, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import App from '../../../assets/images/Dashboard/apps.png';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            {/* <Grid container>
                    <Grid item sx={{ padding: '30px 0 10px 5px' }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ pl: 2, pt: 0.5 }}>
                                <img src={App} alt="App" />
                            </Typography>
                            <Grid sx={{ pl: 2 }}>
                                <LogoSection />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid> */}
            <BrowserView
                style={{
                    overflow: "auto",
                    height: !matchUpMd ? 'calc(100vh - 0)' : 'calc(100vh - 95px)',
                }}
            >
                <MenuList />
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    {/* <MenuCard /> */}
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" className={'menu_sidebar'} sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                className={'menu_sidebar_drawer'}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px',
                            left: "10px",
                            zIndex: "1 !important"
                        }
                    },

                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
