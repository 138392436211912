import axios from 'axios';



const instance = axios.create({
    baseURL: process.env.REACT_APP_USER_ACCESS_API_URL,
});



export const getAllSubscription = async (UserID) => {
    try {
        const { data } = await instance.get(`zoho/subscription/${UserID}`);
        return data;
    } catch (err) {
        return err;
    }
};


export const createSubscription = async (payload) => {
    try {
        const { data } = await instance.post(`zoho/subscription`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


export const updateSubscription = async (payload) => {
    try {
        const { data } = await instance.post(`zoho/subscription/create`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


export const cancelSubscription = async (id) => {
    try {
        const { data } = await instance.delete(`zoho/subscription/${id}/cancel`);
        return data;
    } catch (err) {
        return err;
    }
};





//////super Admin

export const getAllZohoPlan = async () => {
    try {
        const { data } = await instance.get(`zoho/plan/admin`);
        return data;
    } catch (err) {
        return err;
    }
};



///// notificartion
export const getNotificationData = async () => {
    try {
        const { data } = await instance.get(`zoho/plan/admin?filter=notification`);
        return data;
    } catch (err) {
        return err;
    }
};
