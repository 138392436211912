import axios from 'axios';

export const getAllProducts = async () => {
    try {
        const { data } = await axios.get(`products`);
        return data;
    } catch (err) {
        return err;
    }
};
export const createProducts = async (payload) => {
    try {
        const { data } = await axios.post(`products`, payload);
        return data;
    } catch (err) {
        return err;
    }
};
export const updateProduct = async (id, payload) => {
    try {
        const { data } = await axios.put(`products/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};
