import { IconButton, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const NewModalComponent = ({ open, handleClose, children, width, maxWidth, ...rest }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        border: '1px solid #D1D9E0',
        transform: 'translate(-50%, -50%)',
        width: 'calc(100% - 30px)',
        maxWidth: maxWidth || '650px',
        backgroundColor: '#fff',
        maxHeight: 'calc(100% - 30px)',
        overflow: 'auto',
        borderRadius: '20px'
    };
    return (
        <>
            <Modal open={open} onClose={handleClose} {...rest}>
                <Box sx={style} className='newpopup'>
                    <IconButton className="close_modal" color="primary" onClick={handleClose} component="label">
                        <CloseIcon />
                    </IconButton>
                    {children}
                </Box>
            </Modal>
        </>
    );
};

export default NewModalComponent;
